import { Avatar, Box, Button, Paper, TextareaAutosize, Typography } from '@mui/material'
import dayjs from 'dayjs'
import 'dayjs/plugin/utc'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser'
import { useAddNoteMutation, useGetNotesByIdQuery } from '../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../components/Spinner/SpinnerBlock'
import { Note } from './types'

const formatDate = (dateString: string) => {
  return dayjs.utc(dateString).local().format('D MMM h:mm A')
}

const NoteTab = () => {
  const { id } = useParams()
  const orderId = Number(id)
  const {
    data = [] as Note[],
    refetch,
    isLoading,
  } = useGetNotesByIdQuery(orderId, {
    refetchOnMountOrArgChange: true,
  })

  const [notes, setNotes] = useState([...data])

  useEffect(() => {
    setNotes([...data])
  }, [data])

  const { loggedInUser } = useLoggedInUser()

  const [msg, setMsg] = useState('')
  const [addNote] = useAddNoteMutation()

  const handleAddNote = () => {
    const note = {
      message: msg,
      orderId: orderId,
      userId: loggedInUser.id,
      user: {
        id: loggedInUser.id,
        firstName: loggedInUser.firstName ?? '',
        lastName: loggedInUser.lastName ?? '',
      },
      createdDate: new Date().toISOString(),
    }

    setNotes([...notes, note])
    setMsg('')
    addNote({
      body: {
        message: msg,
        orderId: orderId,
        userId: loggedInUser.id,
        user: {
          id: loggedInUser.id,
          firstName: loggedInUser.firstName ?? '',
          lastName: loggedInUser.lastName ?? '',
        },
        createdDate: new Date().toISOString(),
      },
    })
  }

  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      refetch()
    }, 60000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [refetch])
  const width = 500
  if (isLoading) return <SpinnerBlock />

  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        maxWidth={width}
        alignItems='center'
        mx='auto'
        sx={{ pb: '24px', fontFamily: 'Roboto,sans-serif' }}
      >
        <TextareaAutosize
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            outline: 'none',
            border: '1px solid #ccc',
          }}
          minRows={3}
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        <Button
          color='primary'
          style={{ marginLeft: '8px' }}
          onClick={handleAddNote}
          disabled={msg.length === 0}
        >
          Send
        </Button>
      </Box>
      <Box display='flex' justifyContent='center' maxWidth={width} alignItems='center' mx='auto'>
        <Box display='flex' flexDirection='column' maxWidth={width} flexGrow={1} bgcolor='white'>
          {notes.map((note, index) => (
            <Box
              key={index}
              display='flex'
              flexDirection='column'
              alignSelf={note.userId === loggedInUser.id ? 'flex-end' : 'flex-start'}
              width='100%'
              mb={2}
            >
              <Box
                display='flex'
                flexDirection={note.userId === loggedInUser.id ? 'row-reverse' : 'row'}
                alignItems='center'
                width='100%'
              >
                <Avatar
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: '#6363630D',
                    marginTop: '20px',
                    marginRight: note.userId === loggedInUser.id ? '0px' : '8px',
                    marginLeft: note.userId === loggedInUser.id ? '8px' : '0px',
                    alignSelf: 'center',
                  }}
                >
                  {note?.user?.firstName?.charAt(0) ?? ''}
                  {note?.user?.lastName?.charAt(0) ?? ''}
                </Avatar>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    variant='caption'
                    color='textSecondary'
                    align={note.userId === loggedInUser.id ? 'right' : 'left'}
                  >
                    {`${note.user?.firstName} ${note.user?.lastName} ${formatDate(
                      note.createdDate
                    )}`}
                  </Typography>
                  <Paper
                    variant='outlined'
                    style={{
                      padding: '8px 16px',
                      backgroundColor: note.userId === loggedInUser.id ? '#0086D414' : '#6363630D',
                      border: 'none',
                      borderRadius: '8px',
                    }}
                  >
                    <div style={{ overflowWrap: 'break-word' }}>
                      <Typography variant='body1' align={'left'}>
                        {note.message}
                      </Typography>
                    </div>
                  </Paper>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default NoteTab
