import { BaseVariant, OptionsObject, SnackbarOrigin, VariantType } from 'notistack'
import { SubType } from '../types/common'

export const generateRandomId = () => Math.floor(Math.random() * 1000000)

export const isLocaleEquals = (a?: string, b?: string) => {
  if (!a || !b) return false
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
}

export const getSubType = (id?: number | null) =>
  id === undefined ? SubType.SystemDefined : SubType.UserDefined

export const validateRequired = (value: string) => !!value?.trim().length

export const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

const anchorOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

/**
 * Recursively removes specified keys from an object or array.
 * @param obj - The input object or array.
 * @param keysToRemove - An array of keys to exclude.
 * @param skipPaths - An array of property paths to skip processing.
 * @returns A new object or array with the specified keys removed.
 */
export const removeKeys = (
  obj: any,
  keysToRemove: string[] = [],
  skipPaths: string[] = []
): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeys(item, keysToRemove, skipPaths))
  } else if (obj instanceof Date) {
    return obj
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key]) => !keysToRemove.includes(key))
        .map(([key, value]) => [
          key,
          skipPaths.includes(key) ? value : removeKeys(value, keysToRemove, skipPaths),
        ])
        .filter(([_, value]) => value !== undefined)
    )
  }
  return obj
}

const baseVariants: BaseVariant[] = ['default', 'error', 'success', 'warning', 'info']

export type NotiStackOptionsObject = Record<VariantType, OptionsObject>

export const NotiStackOptions: NotiStackOptionsObject = baseVariants.reduce((acc, variant) => {
  acc[variant] = {
    variant,
    anchorOrigin,
  }
  return acc
}, {} as NotiStackOptionsObject)

export function nameof<T extends object>(
  o: T,
  expression: (x: { [Property in keyof T]: string }) => string
) {
  const res = {} as { [Property in keyof T]: string }
  Object.keys(o).map((k) => (res[k as keyof T] = k))
  return expression(res)
}

export type NumberFormatConfig = {
  locale?: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

const defaultConfig: NumberFormatConfig = {
  locale: 'en-US',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const formatNumber = (value: number, config: Partial<NumberFormatConfig> = {}) => {
  const finalConfig = { ...defaultConfig, ...config }
  return new Intl.NumberFormat(finalConfig.locale, {
    minimumFractionDigits: finalConfig.minimumFractionDigits,
    maximumFractionDigits: finalConfig.maximumFractionDigits,
  }).format(value)
}

export const normalizeDate = (d: any) => {
  const dateOnly = new Date(d)
  return dateOnly
}
