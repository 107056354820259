import { Box } from '@mui/material'
import { DesktopDateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs' // Ensure you're using dayjs for date manipulation
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { InputHeader } from '../Headers/styles' // Assuming you have a custom InputHeader

type FormDatePickerInputProps = {
  name: string
  label: string
  minDate?: string | Date
  maxDate?: string | Date
  disablePast?: boolean
  disabled?: boolean
  format?: string
}

const FormDatePickerInput: React.FC<FormDatePickerInputProps> = ({
  name,
  label,
  minDate,
  maxDate,
  disablePast = false,
  disabled = false,
  format = 'DD/MM/YYYY',
}) => {
  const { control } = useFormContext()

  return (
    <Box mb={2} width='100%'>
      <InputHeader>{label}</InputHeader>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DesktopDateTimePicker
            disablePast={disablePast}
            disabled={disabled}
            ampm={false}
            format={format}
            value={field.value ? dayjs(field.value) : null}
            minDate={dayjs(minDate)}
            maxDate={dayjs(maxDate ?? new Date('2100-01-01'))}
            views={
              format.includes('HH') || format.includes('mm')
                ? ['year', 'month', 'day', 'hours', 'minutes']
                : ['year', 'month', 'day']
            }
            onChange={(date) => {
              if (date && date.isValid()) {
                const localDate = date.toDate()
                field.onChange(
                  new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)
                ) // Handle timezone offset correction
              }
            }}
            slotProps={{
              textField: {
                id: name,
                name: name,
                error: !!error,
                fullWidth: true,
                InputLabelProps: { shrink: true },
                helperText: error ? error.message : '',
                InputProps: {
                  sx: {
                    width: '100%',
                    color: '#636363',
                  },
                },
              },
            }}
          />
        )}
      />
    </Box>
  )
}

export default FormDatePickerInput
