import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useAddContactTypeMutation,
  useGetContactCategoriesByCustomerIdQuery,
  useGetContactCategoriesByTenantIdQuery,
  useUpdateContactTypeMutation,
} from '../../../../app/redux-fetch/apiCoreQuery'
import { IDropDownItem } from '../../../../app/types'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import commonMessages from '../../../../components/Messages/commonMessages'
import RequiredAsterisk from '../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../components/Typographies/styles'
import { IContactType } from '../../../Contacts/types'
import messages from '../messages'
import { contactTypeValidationSchema } from './contactTypeValidationSchema'

type ContactTypeFormProps = {
  contactType: IContactType
  handleClose: () => void
  isOpen: boolean
}

const ContactTypeForm: React.FC<ContactTypeFormProps> = ({ contactType, handleClose, isOpen }) => {
  const { formatMessage } = useIntl()
  const isNew = !contactType?.id

  const [addContactType] = useAddContactTypeMutation()
  const [updateContactType] = useUpdateContactTypeMutation()

  const { tenantId, customerId } = useLoggedInUser()

  const { data: contactCategories = [] } = customerId
    ? useGetContactCategoriesByCustomerIdQuery(customerId)
    : useGetContactCategoriesByTenantIdQuery(tenantId)

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const onSubmit = async (data: IContactType) => {
    try {
      if (isNew) {
        await addContactType(data).unwrap()
        enqueueSuccess(messages.contactTypeCreated)
      } else {
        await updateContactType(data).unwrap()
        enqueueSuccess(messages.contactTypeUpdated)
      }
    } catch (error) {
      enqueueFailure(isNew ? messages.contactTypeNotCreated : messages.contactTypeNotUpdated)
    } finally {
      handleClose()
    }
  }

  return (
    <Formik
      initialValues={contactType}
      validationSchema={contactTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
        <Dialog open={isOpen} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Typography sx={{ fontWeight: 700, fontSize: '20px' }}>
                {formatMessage(messages.createEditContactType)}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 450 }}>
              <Box sx={{ mb: 2 }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: 1 }}>
                  {formatMessage(messages.contactTypeName)}
                  <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                  fullWidth
                  id='name'
                  name='name'
                  value={values.name || ''}
                  inputProps={{ maxLength: 50 }}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={touched.name && Boolean(errors.name)}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: 1 }}>
                  {formatMessage(messages.contactCategory)}
                  <RequiredAsterisk />
                </SectionLabelTypography>
                <FormDropdown
                  id='contactCategoryId'
                  items={contactCategories as IDropDownItem[]}
                  disabled={!isNew}
                  onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
                  value={values.contactCategoryId}
                  error={touched.contactCategoryId && Boolean(errors.contactCategoryId)}
                  errorText={touched.contactCategoryId && errors.contactCategoryId}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color='secondary' variant='outlined' onClick={handleClose}>
                {formatMessage(commonMessages.cancelBtn)}
              </Button>
              <Button color='secondary' variant='contained' type='submit' disabled={isSubmitting}>
                {isNew
                  ? formatMessage(commonMessages.createBtn)
                  : formatMessage(commonMessages.updateBtn)}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  )
}

export default ContactTypeForm
