import { zodResolver } from '@hookform/resolvers/zod'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'
import { useGetTransportModeQuery } from '../../app/redux-fetch/apiQuery'
import { useCreateCarrierMutation, useUpdateCarrierMutation } from '../../app/redux-fetch/apiShipment'
import FormSelect from '../../components/Forms/FormSelect'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../components/Typographies/styles'
import { messages } from './messages'
import { CarrierFormData, carrierSchema } from './schema'
import { ICarrier } from './types'

type CarrierFormProps = {
    carrier: ICarrier
    handleClose: () => void
    isOpen: boolean
}

const CarrierForm = ({ carrier, handleClose, isOpen }: CarrierFormProps) => {
    const { formatMessage } = useIntl()
    const isNew = !carrier?.id || carrier?.id === 0

    const [addCarrier] = useCreateCarrierMutation()
    const [updateCarrier] = useUpdateCarrierMutation()
    const { data: transportModes = [] } = useGetTransportModeQuery()
    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const methods = useForm<CarrierFormData>({
        resolver: zodResolver(carrierSchema),
        defaultValues: carrier,
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods

    const onSubmit = async (data: CarrierFormData) => {
        if (isNew) {
            await addCarrier({ tenantId: data.tenantId, body: data })
                .then(() => {
                    enqueueSuccess(messages.carrierCreated)
                    handleClose()
                })
                .catch(() => enqueueFailure(messages.carrierNotAdded))
        } else {
            await updateCarrier({ tenantId: data.tenantId, body: data })
                .then(() => {
                    enqueueSuccess(messages.carrierUpdated)
                    handleClose()
                })
                .catch(() => enqueueFailure(messages.carrierNotUpdated))
        }
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <SectionLabelTypography>
                    {formatMessage(messages.createEditCarrier)}
                </SectionLabelTypography>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                    <DialogContent sx={{ minWidth: '450px' }}>
                        <Box sx={{ mb: '16px' }}>
                            <Box sx={{ mb: '16px' }}>
                                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                    {formatMessage(messages.name)}
                                    <RequiredAsterisk />
                                </SectionLabelTypography>
                                <TextField
                                    fullWidth
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </Box>
                            <Box sx={{ mb: '16px' }}>
                                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                    {formatMessage(messages.code)}
                                </SectionLabelTypography>
                                <TextField
                                    fullWidth
                                    {...register('code')}
                                    error={!!errors.code}
                                    helperText={errors.code?.message}
                                />
                            </Box>
                            <Box sx={{ mb: '16px' }}>
                                <FormSelect
                                    name="transportModeId"
                                    label={formatMessage(messages.transportMode)}
                                    options={transportModes.map((mode) => ({
                                        value: mode.id,
                                        label: mode.name
                                    }))}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color='secondary' variant='outlined' onClick={handleClose}>
                            {formatMessage(messages.cancelBtn)}
                        </Button>
                        <Button color='secondary' variant='contained' type='submit'>
                            {isNew ? formatMessage(messages.newCarrierBtn) : formatMessage(messages.editBtn)}
                        </Button>
                    </DialogActions>
                </FormProvider>
            </form>
        </Dialog>
    )
}

export default CarrierForm
