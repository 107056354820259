import { Edit, Visibility } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_Cell, MRT_Row, MRT_TableInstance, MRT_RowSelectionState as MRTRowSelectionState } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser';
import { useGetCustomersQuery } from '../../../../app/redux-fetch/apiQuery';
import { useGetShipmentSummariesQuery } from '../../../../app/redux-fetch/apiShipment';
import AddButton from '../../../../components/Buttons/AddButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import { default as rootEnum, default as RoutesEnum } from '../../../../components/Routes/rootEnum';
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography';
import { MRTColumnDef, OrderStatuses } from '../../../../types/common';
import messages from '../messages';
import { ShipmentDto } from '../types';

const ShipmentTable = () => {
    const { tenantId, customerId } = useLoggedInUser()

    const { formatMessage, locale } = useIntl()

    const { data: shipments = [], isLoading: shipmentsLoading } = useGetShipmentSummariesQuery(
        {
            tenantId,
            customerId
        }
    )

    const numberFormatter = useMemo(() => {
        return new Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
    }, [locale]);

    const { data: customers, isLoading: customersLoading } = useGetCustomersQuery(tenantId)

    const navigate = useNavigate()

    const currentTime = dayjs.utc();

    const handleNewShipment = useCallback(() => {
        navigate(rootEnum.SHIPMENT_STEPPER)
    }, [navigate])


    const isRowSelectable = useCallback(
        (row: MRT_Row<ShipmentDto>) => {
            const { underReviewUntil, acceptedDate, submittedDate } = row.original;
            const isUnderReview =
                underReviewUntil && dayjs.utc(underReviewUntil).isAfter(currentTime);

            const statusMessage =
                (isUnderReview && formatMessage(messages.lockForReview)) ||
                (acceptedDate && formatMessage(messages.acceptedStatus)) ||
                (submittedDate && formatMessage(messages.submittedStatus)) ||
                formatMessage(messages.draftStatus);

            return statusMessage !== formatMessage(messages.acceptedStatus);
        },
        [formatMessage, currentTime]
    );

    const getCustomerName = (row: ShipmentDto): string => {
        const customer = customers?.find((c) => c.id === row.customerId)
        return customer?.name ?? 'N/A'
    }


    const createRowActions = ({
        row,
    }: {
        cell: MRT_Cell<any>
        row: MRT_Row<any>
        table: MRT_TableInstance<any>
    }) => {
        const { status } = row.original
        const isEditable = (status === OrderStatuses.Draft) || (status === OrderStatuses.Rejected)
        return (
            <Box sx={{ display: 'flex' }}>
                <IconButton
                    color={'info'}
                    onClick={() =>
                        navigate(`${RoutesEnum.VIEW_SHIPMENT.replace(':id', String(row.original.id))}`)
                    }
                >
                    <Visibility />
                </IconButton>
                {isEditable && (
                    <IconButton
                        color={'info'}
                        onClick={() =>
                            navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(row.original.id))}`)
                        }
                    >
                        <Edit />
                    </IconButton>
                )}
            </Box>
        )
    }


    const columns = useMemo<MRTColumnDef<ShipmentDto>[]>(() => {
        return [
            {
                accessorKey: 'customer',
                header: formatMessage(messages.customer),
                Cell: ({ cell }: any) => (
                    <Typography sx={{ fontWeight: 400 }}>{getCustomerName(cell.row.original)}</Typography>
                ),
            },
            {
                accessorKey: 'status',
                header: formatMessage(messages.status),
                Cell: ({ cell }: any) => {
                    return <Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>
                }
            },
            {
                accessorKey: 'lastStatusChangeDate',
                header: formatMessage(messages.lastStatusChangeDate),
                accessorFn: (row) => {
                    return <Typography>{row.lastStatusChangeDate ? dayjs(row.lastStatusChangeDate).format('YYYY-MM-DD HH:ss') : ''}</Typography>
                }
            },
            {
                accessorKey: 'code',
                header: formatMessage(messages.shipmentTrackingNumber),
                Cell: ({ cell }: any) => (
                    <Typography>{cell.getValue()}</Typography>
                ),
            },
            {
                accessorKey: 'customerReferenceNumber',
                header: formatMessage(messages.customerReferenceNumber),
                Cell: ({ cell }: any) => (
                    <Typography>{cell.getValue()}</Typography>
                ),
            },
            {
                accessorKey: 'description',
                header: formatMessage(messages.description),
                Cell: ({ cell }: any) => (
                    <Typography sx={{ fontWeight: 400 }}>{cell.getValue()}</Typography>
                ),
            },
        ];
    }, [formatMessage, shipments, customers]);

    const renderMoneyValue = (value: number, iso3: string) => {
        return `${iso3} ${value?.toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    const renderUnitValue = (value?: number) => {
        return value ? numberFormatter.format(value) : 'N/A'
    }

    const consignmentColumns = useMemo<MRTColumnDef<ShipmentDto>[]>(() => {
        return [
            {
                accessorKey: 'trackingNumber',
                header: `${formatMessage(messages.consignmentTrackingNumber)} #`,
            },
            {
                accessorKey: 'shipFromName',
                header: formatMessage(messages.shipFrom),
            },
            {
                accessorKey: 'deliverToName',
                header: formatMessage(messages.deliverTo),
            },
            {
                accessorKey: 'shipper',
                header: formatMessage(messages.shipper),
                Cell: ({ cell }: any) => (
                    <Typography sx={{ fontWeight: 400 }}>{cell.getValue() ?? 'N/A'}</Typography>
                ),
            },
            {
                accessorKey: 'transportMode',
                header: formatMessage(messages.transportMode),
            },
            {
                accessorKey: 'loads',
                header: formatMessage(messages.loads),
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.loads?.map((pkg: any, index: number) => (
                            <Typography key={index}>{pkg.loadTypeName}</Typography>
                        ))}
                    </div>
                ),
            },
            {
                header: formatMessage(messages.quantity),
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableHeadCellProps: {
                    align: 'right',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.loads?.map((pkg: any, index: number) => (
                            <Typography key={index}>{pkg.quantity}</Typography>
                        ))}
                    </div>
                ),

            },
            {
                header: formatMessage(messages.weight),
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableHeadCellProps: {
                    align: 'right',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.loads?.map((pkg: any, index: number) => (
                            <Typography key={index}>{renderUnitValue(pkg.weight)} {pkg.weightUnit ?? ''}</Typography>
                        ))}
                    </div>
                ),
            },
            {
                header: formatMessage(messages.temperatureControlled),
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableHeadCellProps: {
                    align: 'right',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.loads?.map((load: any, index: number) => (
                            load.temperatureControlled ? <Typography key={index} sx={{ fontWeight: 700, color: '#0088A0DE' }}>Yes</Typography> : <Typography key={index} sx={{ fontWeight: 700 }}>No</Typography>
                        ))}
                    </div>
                ),
            },
            {
                header: formatMessage(messages.cargoValue),
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableHeadCellProps: {
                    align: 'right',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.loads?.map((pkg: any, index: number) => (
                            <Typography key={index}>{renderMoneyValue(pkg.value, pkg.currency)}</Typography>
                        ))}
                    </div>
                ),
            },
        ];
    }, [formatMessage, shipments, customers]);



    const renderConsignmentTable = ({ row }: any) => {
        return (
            <MRTDataGridv2
                state={{ isLoading: false }}
                columns={consignmentColumns}
                data={row.original.shipmentBookingSummaries ?? []}
                getRowId={(row) => row.id as any}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
            />
        )
    }

    const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({});
    const [selectedShipments, setSelectedShipments] = useState<ShipmentDto[]>([]);

    useEffect(() => {
        const selectedRowIds = Object.keys(rowSelection);
        const selectedRows = shipments.filter((shipment: any) =>
            selectedRowIds.includes(String(shipment.id))
        );
        setSelectedShipments(selectedRows);
    }, [rowSelection, shipments]);

    return (<>
        <MRTDataGridv2
            initialState={{
                pagination: { pageSize: 100, pageIndex: 0 },
                density: 'comfortable',
                sorting: [
                    {
                        id: 'status',
                        desc: false,
                    },
                ],
            }}
            state={{
                isLoading: shipmentsLoading || customersLoading,
                rowSelection: rowSelection,
            }}
            leftHeadingComponent={
                <Box sx={{ display: 'inline-flex' }}>
                    <TableTitleTypography>{formatMessage(messages.shipments)}</TableTitleTypography>
                </Box>
            }
            rightHeadingComponent={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <AddButton
                        text={formatMessage(messages.newShipment)}
                        onClick={handleNewShipment}
                    />
                </Box>
            }
            columns={columns}
            data={shipments}
            enableEditing
            enableRowActions
            enableExpanding
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection}
            positionActionsColumn='last'
            renderRowActions={createRowActions}
            renderDetailPanel={renderConsignmentTable}
        />
    </>)
}

export default ShipmentTable;
