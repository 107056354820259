import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  workforceTypeTableTitle: {
    id: 'workforceType.tableTitle',
    defaultMessage: 'Workforce Types',
  },
  name: {
    id: 'workforceType.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'workforceType.description',
    defaultMessage: 'Description',
  },
  inUse: {
    id: 'workforceType.inUse',
    defaultMessage: 'In Use',
  },
  newWorkforceTypeBtn: {
    id: 'workforceType.newBtn',
    defaultMessage: 'Add',
  },
  editBtn: {
    id: 'workforceType.editBtn',
    defaultMessage: 'Edit',
  },
  cancelBtn: {
    id: 'workforceType.cancelBtn',
    defaultMessage: 'Cancel',
  },
  createEditWorkforceType: {
    id: 'workforceType.createEdit',
    defaultMessage: 'Create / Edit Workforce Type',
  },
  workforceTypeDeleted: {
    id: 'workforceType.deleted',
    defaultMessage: 'Workforce Type Deleted',
  },
  workforceTypeCreated: {
    id: 'workforceType.created',
    defaultMessage: 'Workforce Type Created',
  },
  workforceTypeUpdated: {
    id: 'workforceType.updated',
    defaultMessage: 'Workforce Type Updated',
  },
  workforceTypeNotUpdated: {
    id: 'workforceType.notUpdated',
    defaultMessage: 'Workforce Type Not Updated',
  },
  workforceTypeNotAdded: {
    id: 'workforceType.notAdded',
    defaultMessage: 'Workforce Type Not Added',
  },
  workforceTypeNotDeleted: {
    id: 'workforceType.notDeleted',
    defaultMessage: 'Workforce Type Not Deleted',
  },
})
