import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  carrierTableTitle: {
    id: 'carrier.tableTitle',
    description: 'Title for the carriers table',
    defaultMessage: 'Carriers',
  },
  name: {
    id: 'carrier.name',
    description: 'Label for carrier name field',
    defaultMessage: 'Name',
  },
  code: {
    id: 'carrier.code',
    description: 'Label for carrier code field',
    defaultMessage: 'Code',
  },
  transportMode: {
    id: 'carrier.transportMode',
    description: 'Label for transport mode field',
    defaultMessage: 'Transport Mode',
  },
  newCarrierBtn: {
    id: 'carrier.newBtn',
    description: 'Button text for adding new carrier',
    defaultMessage: 'Add',
  },
  editBtn: {
    id: 'carrier.editBtn',
    description: 'Button text for editing carrier',
    defaultMessage: 'Edit',
  },
  cancelBtn: {
    id: 'carrier.cancelBtn',
    description: 'Button text for canceling operation',
    defaultMessage: 'Cancel',
  },
  createEditCarrier: {
    id: 'carrier.createEdit',
    description: 'Title for create/edit carrier form',
    defaultMessage: 'Create / Edit Carrier',
  },
  carrierDeleted: {
    id: 'carrier.deleted',
    description: 'Success message for carrier deletion',
    defaultMessage: 'Carrier Deleted',
  },
  carrierCreated: {
    id: 'carrier.created',
    description: 'Success message for carrier creation',
    defaultMessage: 'Carrier Created',
  },
  carrierUpdated: {
    id: 'carrier.updated',
    description: 'Success message for carrier update',
    defaultMessage: 'Carrier Updated',
  },
  carrierNotUpdated: {
    id: 'carrier.notUpdated',
    description: 'Error message for failed carrier update',
    defaultMessage: 'Carrier Not Updated',
  },
  carrierNotAdded: {
    id: 'carrier.notAdded',
    description: 'Error message for failed carrier creation',
    defaultMessage: 'Carrier Not Created',
  },
  carrierNotDeleted: {
    id: 'carrier.notDeleted',
    description: 'Error message for failed carrier deletion',
    defaultMessage: 'Carrier Not Deleted',
  },
  inUse: {
    id: 'carrier.form.inUse',
    defaultMessage: 'In Use',
  },
})
