import { useEffect, useState } from 'react';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useModalWithForm } from '../../../app/hooks/useModalWithForm';
import { useGetLocationAuditsQuery, useGetLocationsQuery } from '../../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../../app/utils';
import { Option } from '../../../components/Forms/FormSelect';

function mapAddressAuditToAddress(addressAudit: any): any {
  if (!addressAudit) return null;
  return {
    addressId: addressAudit.id,
    address1: addressAudit.address1,
    address2: addressAudit.address2,
    city: addressAudit.city,
    state: addressAudit.state,
    countryId: addressAudit.countryId,
    postalCode: addressAudit.postalCode,
  };
}

function mapLocationAuditToLocation(audit: any): any {
  return {
    id: audit.id,
    tenantId: audit.tenantId,
    customerId: audit.customerId,
    name: audit.name,
    latitude: audit.latitude,
    longitude: audit.longitude,
    locationTypeId: audit.locationTypeId,
    addressAudit: mapAddressAuditToAddress(audit.addressAudit),
    contacts: audit.contacts,
    geofence: audit.geofence,
    defaultTransportModeId: audit.defaultTransportModeId,
    deleted: audit.deleted,
  };
}

interface UseLocationModalProps {
  onSave: (data: any) => void;
  customerId?: number;
  filterFunction?: (item: any) => boolean;
  oldLocations?: any[];
}

export const useLocationModal = ({
  onSave,
  filterFunction,
  customerId,
  oldLocations: oldShipmentLocations = [],
}: UseLocationModalProps) => {
  const { tenantId } = useLoggedInUser();

  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [locationAuditOptions, setLocationAuditOptions] = useState<any[]>([]);
  const [editingLocation, setEditingLocation] = useState(null);

  const { data: locations = [] } = useGetLocationsQuery({ tenantId, customerId });
  const {
    data: locationAudits = [],
    refetch: refetchLocationAudits,
  } = useGetLocationAuditsQuery({ tenantId, customerId });

  useEffect(() => {
    let filteredLocations = locations;
    let filteredLocationAudits = locationAudits;

    if (filterFunction) {
      filteredLocations = filteredLocations.filter(filterFunction);
      filteredLocationAudits = filteredLocationAudits.filter(filterFunction);
    }

    const locationAuditMap = new Map<number, any>(
      filteredLocationAudits.map((audit: any) => [audit.locationId, audit])
    );

    const mergedLocations = filteredLocations.map((location: any) => {
      const audit = locationAuditMap.get(location.id);
      if (audit) {
        const auditedLocation = mapLocationAuditToLocation(audit);
        return {
          ...location,
          ...auditedLocation,
        };
      }
      return location;
    });

    const allLocations = [...mergedLocations];

    oldShipmentLocations.forEach((oldLoc) => {
      const exists = allLocations.find((loc) => loc.id === oldLoc.id);
      if (!exists) {
        allLocations.push(oldLoc);
      }
    });

    const locationOptionsValue = convertToLabelValue(allLocations);
    setLocationOptions(locationOptionsValue);
    setLocationAuditOptions(allLocations);
  }, [locations, locationAudits]);

  const handleOnSave = async (createdOrUpdatedLocation: any) => {
    const locationId = createdOrUpdatedLocation.data?.id;

    if (!locationId) {
      onSave(null);
      return;
    }

    const { data: updatedLocationAudits } = await refetchLocationAudits();
    if (updatedLocationAudits) {
      const matchedAudit = updatedLocationAudits.find((audit: any) => audit.LocationId === locationId);
      if (matchedAudit) {
        const mappedLocation = mapLocationAuditToLocation(matchedAudit);
        onSave(mappedLocation.id);
      } else {
        const oldLocMatch = oldShipmentLocations.find((oldLoc) => oldLoc.id === locationId);
        if (oldLocMatch) {
          onSave(oldLocMatch.id);
        } else {
          onSave(null);
        }
      }
    } else {
      const oldLocMatch = oldShipmentLocations.find((oldLoc) => oldLoc.id === locationId);
      if (oldLocMatch) {
        onSave(oldLocMatch.id);
      } else {
        onSave(null);
      }
    }
  };

  const {
    isModalOpen,
    openModal: baseOpenModal,
    closeModal,
    ModalComponent,
  } = useModalWithForm({
    type: 'location',
    onSave: handleOnSave,
    data: editingLocation ? editingLocation : { customerId, enabled: true, isOrderLocation: true },
  });

  const openModal = (location?: any) => {
    setEditingLocation(location ? { ...location, isOrderLocation: true } : { customerId, isOrderLocation: true });
    baseOpenModal();
  };

  return {
    locationOptions,
    locations,
    locationAuditOptions,
    locationAudits,
    isModalOpen,
    openModal,
    closeModal,
    ModalComponent,
  };
};
