import { Box, Grid } from '@mui/material'
import { Formik } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import {
  useCreateLocationMutation,
  useUpdateLocationMutation,
} from '../../app/redux-fetch/apiShipment'
import SubmitButtons from '../../components/Buttons/SubmitButtons'
import { ContactCategoryTypeId } from '../../types/common'
import { useSideModalContext } from '../AssetsManagement/modals/SideModalContext'
import ContactTable from '../Contacts/ContactTable'
import { ILocationByTenantCustomer } from '../Shipments/types'
import { fetchLoggedInUserSelector } from '../selectors'
import AddressDetails from './components/AddressDetails'
import DefaultValues from './components/DefaultValues'
import MainInfo from './components/MainInfo'
import { getLocationValidationSchema } from './validation'

export interface ILocationForm {
  location?: ILocationByTenantCustomer
  isDisabled?: boolean
  isCustomerDisabled?: boolean
  onSubmit?: (data: any) => void
}

const LocationForm = ({ location, isCustomerDisabled, isDisabled, onSubmit }: ILocationForm): ReactElement<any, any> => {

  const isNew = !location?.id
  const { formatMessage } = useIntl()

  const { onClose } = useSideModalContext()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)

  const customerId = location?.customerId ?? loggedInUser?.customerId

  const tenantId = loggedInUser?.tenantId

  const [createLocation] = useCreateLocationMutation()
  const [updateLocation] = useUpdateLocationMutation()

  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId

  return (
    <Formik
      initialValues={{
        ...location,
        tenantId,
        customerId,
      }}
      validationSchema={getLocationValidationSchema(formatMessage)}
      onSubmit={async (values: ILocationByTenantCustomer) => {
        if (isNew) {
          values.locationTypeId = 255
          const data = await createLocation({ tenantId, customerId: values.customerId, body: values })
          onSubmit && onSubmit(data)
        } else {
          await updateLocation({ tenantId, customerId: values.customerId, body: values })
        }
        onClose()
      }}
    >
      <Grid container spacing={2} sx={{ p: '48px' }}>
        <Grid display='flex' flexDirection='row' gap={4} width='100%'>
          <Grid display='flex' flexDirection='column' width='50%'>
            <MainInfo
              type={isNew ? 'create' : 'edit'}
              isTenantUser={Boolean(isTenantUser)}
              isDisabled={isDisabled}
              isCustomerDisabled={isCustomerDisabled}
              isOrderLocation={location?.isOrderLocation}
            />
            <DefaultValues isDisabled={isDisabled} />
            <ContactTable isDisabled={isDisabled} contactCategoryId={ContactCategoryTypeId.location} />
          </Grid>
          <Grid display='flex' flexDirection='column' width='50%'>
            <Grid item xs={12}>
              <AddressDetails isDisabled={isDisabled} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ pr: '32px' }}>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              padding: '16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #e0e0e0',
              zIndex: 999,
            }}
          >
            {!isDisabled && <SubmitButtons create={isNew} />}
          </Box>
        </Grid>
      </Grid>
    </Formik>
  )
}

export default LocationForm
