import { AccordionDetails, Button, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetContactAuditByEntityIdQuery } from '../../../app/redux-fetch/apiCoreQuery'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import FormDatePickerInput from '../../../components/Forms/FormDatePickerInput'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import commonMessages from '../../../components/Messages/commonMessages'
import { useLocationModal } from '../hooks/useLocationModal'
import FlowSection from './FlowSection'
import messages from './messages'
import TransitDetailsSection from './TransitDetailsSection'
import { getLocationAddress, mapContactsToOptions } from './utils'

interface EmptyContainerCollectionProps {
  consignmentIndex: number
}

const EmptyContainerCollection: React.FC<EmptyContainerCollectionProps> = ({
  consignmentIndex,
}) => {
  const { setValue, watch } = useFormContext()
  const [expanded, setExpanded] = useState(true)

  const { formatMessage } = useIntl()

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const customerId = watch('customerId')

  const oldLocations = watch(`bookings.${consignmentIndex}.emptyContainerCollection.locationAudit`)

  const {
    openModal,
    ModalComponent,
    locationOptions,
    locationAudits,
    locationAuditOptions,
    locations,
  } = useLocationModal({
    onSave: (id) => {
      setValue(`bookings.${consignmentIndex}.emptyContainerCollection.locationId`, id)
    },
    customerId,
    oldLocations: oldLocations ? [oldLocations] : [],
  })

  const emptyContainerCollection = watch(
    `bookings.${consignmentIndex}.shipFrom.emptyContainerCollection`
  )

  const locationId = watch(`bookings.${consignmentIndex}.emptyContainerCollection.locationId`)

  const getLocationIdFromLocationAudit = (locationAuditId: number) => {
    return locationAudits.find((loc: any) => loc.id === locationAuditId)?.locationId
  }

  const { data: contacts } = useGetContactAuditByEntityIdQuery(
    getLocationIdFromLocationAudit(locationId),
    {
      skip: !locationId,
      refetchOnMountOrArgChange: true,
    }
  )

  const getContactOptions = useCallback(() => {
    return mapContactsToOptions(contacts)
  }, [contacts])

  const selectedLocation = locations.find(
    (l: any) => l.id === locationAudits.find((loc: any) => loc.id === locationId)?.locationId
  )

  useEffect(() => {
    if (emptyContainerCollection)
      if (selectedLocation?.defaultTransportModeId) {
        setValue(
          `bookings.${consignmentIndex}.emptyContainerCollection.transitDetails.modeOfTransit`,
          selectedLocation?.defaultTransportModeId
        )
      } else {
        setValue(
          `bookings.${consignmentIndex}.emptyContainerCollection.transitDetails.modeOfTransit`,
          -1
        )
      }
  }, [selectedLocation, locationId, emptyContainerCollection])

  if (!emptyContainerCollection) {
    return null
  }

  return (
    <>
      {ModalComponent}
      <FlowSection>
        <StyledAccordion
          defaultExpanded
          sx={{ marginTop: '10px' }}
          onChange={handleToggleAccordion}
        >
          <StyledAccordionSummary
            index={`emptyContainerCollection-content-${consignmentIndex}`}
            expanded={expanded}
            title={formatMessage(messages.emptyContainerCollection)}
          />
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormSelect
                  name={`bookings.${consignmentIndex}.emptyContainerCollection.locationId`}
                  label={formatMessage(messages.location)}
                  options={locationOptions}
                  onChange={(e) => {
                    setValue(
                      `bookings.${consignmentIndex}.emptyContainerCollection.contactId`,
                      null
                    )
                  }}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        openModal()
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      {formatMessage(commonMessages.addNew)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDatePickerInput
                  name={`bookings.${consignmentIndex}.emptyContainerCollection.scheduledDate`}
                  label={formatMessage(messages.scheduledDate)}
                  format='DD/MM/YYYY HH:mm'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name={`bookings.${consignmentIndex}.emptyContainerCollection.contactId`}
                  label={formatMessage(messages.contact)}
                  disableLabel={formatMessage(messages.noContactsAvailable)}
                  options={getContactOptions()}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        setValue(
                          `bookings.${consignmentIndex}.emptyContainerCollection.contactId`,
                          null
                        )
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      {formatMessage(messages.clearSelection)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1' gutterBottom sx={{ color: '#636363' }}>
                  {formatMessage(messages.address)}
                </Typography>
                <Typography variant='body2' sx={{ color: '#636363' }}>
                  {getLocationAddress(locationAuditOptions, locationId)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={`bookings.${consignmentIndex}.emptyContainerCollection.specialInstructions`}
                  label={formatMessage(messages.specialInstructions)}
                  placeholder={formatMessage(commonMessages.pleaseEnter)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </FlowSection>
      <TransitDetailsSection
        namePrefix={`bookings.${consignmentIndex}.emptyContainerCollection.transitDetails`}
      />
    </>
  )
}

export default EmptyContainerCollection
