import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import ToggleContainer from '../../../components/Containers/ToggleContainer'
import FlowSection from './FlowSection'
import messages from './messages'
import TransitDetailsForm from './TransitDetailsForm'
import TransportIcon from './TransportIcon'
import WaypointForm from './WaypointForm'

interface WaypointContainerProps {
  consignmentIndex: number
}

const WaypointContainer: React.FC<WaypointContainerProps> = ({
  consignmentIndex,
}: WaypointContainerProps) => {
  const { control, trigger, watch } = useFormContext()
  const [isOpenTransitDetails, setIsOpenTransitDetails] = useState(true)
  const { formatMessage } = useIntl()

  const handleToggleTransitDetail = () => {
    setIsOpenTransitDetails((prev) => !prev)
  }

  const { insert, fields, remove, append } = useFieldArray({
    control,
    name: `bookings.${consignmentIndex}.waypoints`,
  })

  const transitDetailMode = watch(
    `bookings.${consignmentIndex}.shipFrom.transitDetails.modeOfTransit`
  )

  return (
    <>
      <FlowSection icon={<TransportIcon id={transitDetailMode} />} showIcon={isOpenTransitDetails}>
        <Box sx={{ p: `${!isOpenTransitDetails ? '8px' : '32px'}` }}>
          <ToggleContainer
            title={formatMessage(messages.transitDetails)}
            isOpen={isOpenTransitDetails}
            handleToggle={handleToggleTransitDetail}
          >
            <TransitDetailsForm
              namePrefix={`bookings.${consignmentIndex}.shipFrom.transitDetails`}
            />
          </ToggleContainer>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          <Box>
            {!isOpenTransitDetails && (
              <Button
                onClick={handleToggleTransitDetail}
                sx={{ textTransform: 'none', marginBottom: 2 }}
              >
                {formatMessage(messages.showTransitDetails)}
              </Button>
            )}
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              startIcon={<AddIcon />}
              onClick={() => {
                append({
                  location: '',
                  transitDetails: {
                    carrierOption: 'noPreference',
                  },
                })
              }}
              sx={{ textTransform: 'none', marginBottom: 2 }}
            >
              {formatMessage(messages.addWaypoint)}
            </Button>
          </Box>
        </Box>
      </FlowSection>
      {fields.map((field, waypointIndex) => (
        <>
          <WaypointForm
            key={field.id}
            consignmentIndex={consignmentIndex}
            waypointIndex={waypointIndex}
            removeWaypoint={remove}
            insertWaypoint={insert}
          />
        </>
      ))}
    </>
  )
}

export default WaypointContainer
