import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Link } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'
import {
  useDeleteWorkforceTypeMutation,
  useGetWorkforceTypesByTenantIdQuery,
} from '../../app/redux-fetch/apiUser'
import AddButton from '../../components/Buttons/AddButton'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography'
import WorkforceTypeForm from './WorkforceTypeForm'
import { messages } from './messages'
import { IWorkforceType } from './types'

const WorkforceTypeTable = () => {
  const { formatMessage } = useIntl()
  const { tenantId } = useLoggedInUser()
  const [selectedId, setSelectedId] = useState(-1)
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const [deleteWorkforceType] = useDeleteWorkforceTypeMutation()
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const {
    data: workforceTypes = [],
    isFetching,
    isSuccess,
  } = useGetWorkforceTypesByTenantIdQuery({ tenantId })

  const columns: MRTColumnDef<IWorkforceType>[] = [
    {
      accessorKey: 'name',
      header: formatMessage(messages.name),
    },
    {
      accessorKey: 'description',
      header: formatMessage(messages.description),
    },
    {
      accessorKey: 'inUse',
      header: formatMessage(messages.inUse),
      Cell: ({ cell }: any) => <YesNoTypography isYes={cell.getValue()} />,
    },
  ]

  const handleDelete = (id: number) => {
    deleteWorkforceType({ tenantId, body: { id } })
      .then(() => {
        enqueueSuccess(messages.workforceTypeDeleted)
      })
      .catch(() => {
        enqueueFailure(messages.workforceTypeNotDeleted)
      })
  }

  return (
    <>
      <ConditionalRender condition={createOpen}>
        <WorkforceTypeForm
          workforceType={{
            tenantId,
            name: '',
            description: '',
          }}
          handleClose={() => setCreateOpen(false)}
          isOpen={createOpen}
        />
      </ConditionalRender>

      <ConditionalRender condition={editOpen}>
        <WorkforceTypeForm
          workforceType={
            workforceTypes.find((wt) => wt.id === selectedId) ?? {
              tenantId,
              name: '',
              description: '',
            }
          }
          handleClose={() => setEditOpen(false)}
          isOpen={editOpen}
        />
      </ConditionalRender>

      <Box>
        <TableTitleTypography>
          {formatMessage(messages.workforceTypeTableTitle)}
        </TableTitleTypography>
      </Box>

      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          density: 'comfortable',
          sorting: [{ id: 'name', desc: false }],
        }}
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newWorkforceTypeBtn)}
            onClick={() => setCreateOpen(true)}
          />
        }
        state={{
          isLoading: isFetching || !isSuccess,
        }}
        columns={columns}
        data={workforceTypes}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <Link
              underline='none'
              color='secondary'
              onClick={() => {
                setSelectedId(row.original.id ?? -1)
                setEditOpen(true)
              }}
              sx={{ fontWeight: 700, cursor: 'pointer' }}
            >
              {formatMessage(messages.editBtn)}
            </Link>
            <ConditionalRender condition={!row.original.inUse}>
              <CancelIcon
                color='secondary'
                sx={{ cursor: 'pointer', pl: '4px' }}
                onClick={() => handleDelete(row.original.id ?? -1)}
              />
            </ConditionalRender>
          </Box>
        )}
      />
    </>
  )
}

export default WorkforceTypeTable
