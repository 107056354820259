import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useDeleteContactCategoryMutation,
  useGetContactCategoriesByCustomerIdQuery,
  useGetContactCategoriesByTenantIdQuery,
} from '../../../../app/redux-fetch/apiCoreQuery'
import commonMessages from '../../../../components/Messages/commonMessages'
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import { IContactCategory } from '../../../Contacts/types'
import messages from '../messages'
import ContactCategoryForm from './ContactCategoryForm'

const ContactCategoryTab: React.FC = () => {
  const { formatMessage } = useIntl()
  const { tenantId, customerId } = useLoggedInUser()
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()
  const [deleteContactCategory] = useDeleteContactCategoryMutation()

  const {
    data: contactCategories = [],
    isFetching: contactsLoading,
    isSuccess: contactsLoaded,
  } = customerId
    ? useGetContactCategoriesByCustomerIdQuery(customerId)
    : useGetContactCategoriesByTenantIdQuery(tenantId)

  const columns = useMemo<MRT_ColumnDef<IContactCategory>[]>(
    () => [
      {
        accessorKey: 'name',
        header: formatMessage(commonMessages.name),
        Cell: ({ cell }: { cell: MRT_Cell<IContactCategory> }) => (
          <Typography>{cell.getValue<string>()}</Typography>
        ),
      },
    ],
    [formatMessage]
  )

  const handleDeleteContactCategory = async (id: number) => {
    try {
      await deleteContactCategory(id).unwrap()
      enqueueSuccess(messages.contactCategoryDeleted)
    } catch (error) {
      enqueueFailure(messages.contactCategoryNotDeleted)
    }
  }

  const selectedContactCategory = contactCategories.find((c) => c.id === selectedCategoryId) ?? {
    tenantId: +tenantId,
    customerId: customerId,
    id: 0,
    name: '',
  }

  return (
    <>
      {createOpen && (
        <ContactCategoryForm
          category={{
            tenantId: +tenantId,
            customerId: customerId,
            id: 0,
            name: '',
          }}
          handleClose={() => setCreateOpen(false)}
          isOpen={createOpen}
        />
      )}
      {editOpen && (
        <ContactCategoryForm
          category={selectedContactCategory}
          handleClose={() => setEditOpen(false)}
          isOpen={editOpen}
        />
      )}
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          density: 'comfortable',
          sorting: [{ id: 'name', desc: false }],
        }}
        leftHeadingComponent={
          <Box sx={{ display: 'inline-flex' }}>
            <TableTitleTypography>{formatMessage(messages.contactCategories)}</TableTitleTypography>
          </Box>
        }
        state={{
          isLoading: contactsLoading || !contactsLoaded,
        }}
        data={contactCategories}
        columns={columns}
        getRowId={(row) => row.id?.toString()}
        enableRowActions={false}
        positionActionsColumn='last'
      />
    </>
  )
}

export default ContactCategoryTab
