import React from 'react';
import { Alert, AlertProps, AlertTitle, Grid } from '@mui/material';

interface CustomAlertProps extends Omit<AlertProps, 'title'> {
    title?: string;
    message: string;
    gridProps?: {
        xs?: number;
        spacing?: number;
        sx?: object;
    };
}

const CustomAlert: React.FC<CustomAlertProps> = ({
    title,
    message,
    gridProps = { xs: 12, sx: { pb: '16px' } },
    ...alertProps
}) => {
    return (
        <Grid item {...gridProps}>
            <Alert {...alertProps}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        </Grid>
    );
};

export default CustomAlert;
