import { Box, Link, Typography } from '@mui/material'
import {
    MRT_ColumnDef as MRTColumnDef
} from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetCustomersQuery, useTrackingNumberTemplateQuery } from '../../../app/redux-fetch/apiQuery'
import { useGetCountriesQuery } from '../../../app/redux-fetch/apiShipment'
import CustomAlert from '../../../components/Alerts/CustomAlert'
import AddButton from '../../../components/Buttons/AddButton'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography'
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import CreateCustomer from './CreateCustomer'
import EditCustomer from './EditCustomer'
import messages from './messages'


const CustomerTable = () => {
    const { formatMessage } = useIntl()
    const [createOpen, setCreateOpen] = useState(false)

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { tenantId } = loggedInUser

    const { data: customers, isSuccess: isCustomerLoaded } = useGetCustomersQuery(tenantId ?? -1,
        { refetchOnMountOrArgChange: true })

    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery()

    const {
        data: template,
        isFetching: isTemplateFetching,
        isSuccess: isTemplateLoaded,
        refetch,
    } = useTrackingNumberTemplateQuery({ tenantId })

    const isTrackingNumberSetup = useMemo(() => !!template, [template])


    const columns: MRTColumnDef<any>[] = [
        {
            accessorKey: 'name',
            header: formatMessage(messages.name),
        },
        {
            accessorKey: 'alias',
            header: formatMessage(messages.alias)
        },
        {
            accessorKey: 'accountNumber',
            header: formatMessage(messages.accountNumber),

        },
        {
            accessorKey: 'location.address.address1',
            header: formatMessage(messages.address1),
            accessorFn: (row) => row.location?.address?.address1 ?? null,
        },
        {
            accessorKey: 'location.address.city',
            header: formatMessage(messages.city),
            accessorFn: (row) => row.location?.address?.city ?? null,
        },
        {
            accessorKey: 'location.address.state',
            header: formatMessage(messages.state),
            accessorFn: (row) => row.location?.address?.state ?? null,
        },
        {
            accessorKey: 'location.address.postalCode',
            header: formatMessage(messages.postalCode),
            accessorFn: (row) => row.location?.address?.postalCode ?? null,
        },
        {
            accessorFn: (row) => countries?.find((c: any) => c.id === row.location?.address?.countryId)?.name ?? '',
            header: formatMessage(messages.country)
        }
    ]

    const [editOpen, setEditOpen] = useState(false)
    const [viewOpen, setViewOpen] = useState(false)
    const [selectedCustomerId, setSelectedCustomerId] = useState(0)
    // if (!isTrackingNumberSetup) {
    //     return (<>
    //         <CustomAlert
    //             severity='error'
    //             title='Error'
    //             message='You must setup your tracking number template before you can add a new customer.'
    //         />
    //     </>)
    // }
    return (
        <>
            <SideModalWrapper
                open={createOpen}
                key={'create'}
                headerLabel={formatMessage(messages.createEditCustomer)}
                onClose={() => {
                    setCreateOpen((pv) => {
                        return !pv
                    })
                }}
                withBottomBox={false}
            >
                {<CreateCustomer />}
            </SideModalWrapper>
            <SideModalWrapper
                open={editOpen}
                key={'edit'}
                headerLabel={formatMessage(messages.createEditCustomer)}
                onClose={() => {
                    setEditOpen((pv) => {
                        return !pv
                    })
                }}
                withBottomBox={false}
            >
                <ConditionalRender condition={editOpen}>
                    <EditCustomer customerId={selectedCustomerId} />
                </ConditionalRender>
            </SideModalWrapper>
            <SideModalWrapper
                open={viewOpen}
                key={'view'}
                headerLabel={formatMessage(messages.viewCustomer)}
                onClose={() => {
                    setViewOpen((pv) => {
                        return !pv
                    })
                }}
                withBottomBox={false}
            >
                <ConditionalRender condition={viewOpen}>
                    <EditCustomer customerId={selectedCustomerId} isDisabled={true} />
                </ConditionalRender>
            </SideModalWrapper>
            {!isTrackingNumberSetup && (<CustomAlert
                severity='error'
                title='Error'
                message='You must setup your tracking number template before you can add a new customer.'
            />)}
            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    columnPinning: { left: ['name'] },
                    sorting: [
                        {
                            id: 'name',
                            desc: false,
                        }]
                }
                }
                state={{
                    isLoading: !isCustomerLoaded || !isCountriesLoaded,
                }}
                leftHeadingComponent={
                    <Box sx={{ display: 'inline-flex' }}>
                        <TableTitleTypography>{formatMessage(messages.customers)}</TableTitleTypography>
                    </Box>
                }
                rightHeadingComponent={
                    <AddButton
                        text={formatMessage(messages.newCustomerLbl)}
                        disabled={!isTrackingNumberSetup}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                columns={columns}
                data={customers || []}
                getRowId={(row) => row.id as any}
                enableEditing
                enableColumnPinning={true}
                enableRowActions
                positionActionsColumn='last'
                renderRowActions={({ row, table }: any) => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedCustomerId(row.id)
                                        setEditOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.editBtn)}
                                </Link>
                                <Typography color='secondary'>/</Typography>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedCustomerId(row.id)
                                        setViewOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.viewBtn)}
                                </Link>
                            </Box>
                        </>
                    )
                }}
            />
        </>)
}

export default CustomerTable
