import { IWorkforceType } from '../../features/WorkforceType/types'
import { apiSlice } from './apiQuery'

export const apiUser = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWorkforceTypesByTenantId: build.query<IWorkforceType[], { tenantId: number }>({
      query: ({ tenantId }) => ({
        url: `/tenant/${tenantId}/workforce-type`,
        method: 'GET',
        domain: 'User',
      }),
      providesTags: (result) => [{ type: 'WorkforceType', id: 'WorkforceType' }],
    }),

    addWorkforceType: build.mutation<number, { tenantId: number; body: IWorkforceType }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/workforce-type`,
        method: 'POST',
        body,
        domain: 'User',
      }),
      invalidatesTags: [{ type: 'WorkforceType', id: 'WorkforceType' }],
    }),

    updateWorkforceType: build.mutation<number, { tenantId: number; body: IWorkforceType }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/workforce-type`,
        method: 'PUT',
        body,
        domain: 'User',
      }),
      invalidatesTags: [{ type: 'WorkforceType', id: 'WorkforceType' }],
    }),

    deleteWorkforceType: build.mutation<void, { tenantId: number; body: { id: number } }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/workforce-type/${body.id}`,
        method: 'DELETE',
        domain: 'User',
      }),
      invalidatesTags: [{ type: 'WorkforceType', id: 'WorkforceType' }],
    }),
  }),
})

export const {
  useGetWorkforceTypesByTenantIdQuery,
  useAddWorkforceTypeMutation,
  useUpdateWorkforceTypeMutation,
  useDeleteWorkforceTypeMutation,
} = apiUser
