import { useFormik } from 'formik'
export type { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'

export enum WaypointType {
  return = 0,
  shipFrom = 1,
  waypoint = 2,
  deliverTo = 3,
  emptyContainerCollection = 4,
  emptyContainerReturn = 5,
}

export enum ContactCategoryTypeId {
  customer = 1,
  location = 2,
}

export enum OrderStatuses {
  Accepted = 'Accepted',
  Draft = 'Draft',
  Submitted = 'Submitted',
  UnderReview = 'Under Review',
  Rejected = 'Rejected',
}

export enum LoadType {
  container = 1,
  pallet = 2,
  parcel = 3,
}

export enum SubType {
  SystemDefined = 'SystemDefined',
  UserDefined = 'UserDefined',
}

// remove

export interface ICommonAttributes {
  id?: number
  name: string
  enabled: boolean
  customerId?: number
  isMotorized: boolean
  entity: 'category' | 'type' | 'class' | 'currency' | 'user' | 'userGroup' | 'role' | 'allocation'
  dirty: boolean
  httpAction: 'POST' | 'PUT' | 'DELETE' | undefined
  assetCategoryId?: number
  transportModeId?: number
  tenantId?: number
  error?: string
  formHasErrors?: boolean
  canDelete?: boolean
  isCloneOfSysmtemDefined?: boolean
  isNewCurrencyRow?: boolean
  isPureUserDefined: boolean
  subType: SubType
  isNew: boolean
}

export type IFormik = ReturnType<typeof useFormik> | any
