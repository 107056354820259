import AddIcon from '@mui/icons-material/Add'
import { AccordionDetails, Button, Grid } from '@mui/material'

import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import {
  useGetCurrenciesByCustomerIdQuery,
  useGetLocationAuditsQuery,
} from '../../../app/redux-fetch/apiShipment'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer'
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem'
import { LabelItem } from '../../../components/Typographies/LabelItem'
import { removeKeys } from '../../utils'
import { useOrderFormContext } from '../context/OrderFormContext'
import PackagingItem from './PackagingItem'
import messages from './messages'

interface PackagingFormProps {
  consignmentIndex: number
}

const PackagingForm: React.FC<PackagingFormProps> = ({ consignmentIndex }) => {
  const { control, watch } = useFormContext()
  const { append, remove } = useFieldArray({
    control,
    name: `bookings.${consignmentIndex}.packaging`,
  })

  const { formatMessage } = useIntl()

  const packaging = watch(`bookings.${consignmentIndex}.packaging`, [])

  const copyPackaging = (index: number) => {
    const packages = packaging[index]
    const load = removeKeys(packages, [
      'id',
      'loadDetailId',
      'physicalPropertiesId',
      'temperatureSettingId',
      'orderId',
      'bookingId',
      'consignmentId',
      'waypointId',
    ])
    append(load)
  }

  const [expanded, setExpanded] = useState(true)
  const { currentPage } = useOrderFormContext()

  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const tenantId = watch('tenantId')
  const customerId = watch('customerId')

  const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
    customerId: customerId ?? 0,
    hideDisabled: true,
  })

  const currencyId = watch(`bookings.${consignmentIndex}.currencyId`)

  const getCurrencyLabel = useCallback(() => {
    const currency = currencies.find((currency) => currency.id === currencyId)
    if (!currency) return 'N/A'
    return `${currency.iso3} - ${currency.localisedSymbol}`
  }, [currencies, currencyId])

  const { data: locations = [] } = useGetLocationAuditsQuery({ tenantId, customerId })

  const consignmentReferenceNumber = watch(`bookings.${consignmentIndex}.referenceNumber`)
  const consignmentTrackingNumber = watch(`bookings.${consignmentIndex}.trackingNumber`)
  const description = watch(`bookings.${consignmentIndex}.description`)
  const shipperId = watch(`bookings.${consignmentIndex}.shipperId`)

  const getLocationName = (locationId: number) => {
    return locations.find((location: any) => location.id === locationId)?.name
  }

  const shipFrom = watch(`bookings.${consignmentIndex}.shipFrom.locationId`)
  const deliverTo = watch(`bookings.${consignmentIndex}.deliverTo.locationId`)

  const loadingDate = dayjs(watch(`bookings.${consignmentIndex}.shipFrom.loadingDate`)).format(
    'DD/MM/YYYY'
  )
  const deliveryDate = dayjs(watch(`bookings.${consignmentIndex}.deliverTo.deliveryDate`)).format(
    'DD/MM/YYYY'
  )
  const renderPackagingItem = () => {
    return currentPage >= 2 ? (
      <>
        <BoxWhiteContainer sx={{ mb: '32px' }}>
          <Grid container>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <LabelItem>{formatMessage(messages.consignmentInformation)}</LabelItem>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.shipper)}
                value={getLocationName(shipperId) ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.currency)}
                value={getCurrencyLabel() ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.customerReferenceNumber)}
                value={consignmentReferenceNumber ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.description)}
                value={description ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.consignmentTrackingNumber)}
                value={consignmentTrackingNumber ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
          </Grid>
        </BoxWhiteContainer>
        <BoxWhiteContainer sx={{ mb: '32px' }}>
          <Grid container>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <LabelItem>{formatMessage(messages.shipFromDeliverTo)}</LabelItem>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.shipFrom)}
                value={getLocationName(shipFrom) ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.deliverTo)}
                value={getLocationName(deliverTo) ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.requestedPickupDate)}
                value={loadingDate ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <InfoDisplayItem
                label={formatMessage(messages.requestedDeliveryDate)}
                value={deliveryDate ?? 'N/A'}
                isSmallScreen={false}
              />
            </Grid>
          </Grid>
        </BoxWhiteContainer>
        <StyledAccordion expanded={expanded} onChange={handleToggleAccordion}>
          <StyledAccordionSummary
            index={`packaging-content-${consignmentIndex}`}
            title={formatMessage(messages.loads)}
          />
          <AccordionDetails>
            {packaging.map((item: any, index: any) => (
              <PackagingItem
                key={item.id}
                consignmentIndex={consignmentIndex}
                packagingIndex={index}
                itemId={item.id}
                remove={handleRemove}
                copy={copyPackaging}
              />
            ))}
            {currentPage == 2 && (
              <Button
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={() =>
                  append({
                    numberOfPackages: 1,
                    temperatureControlled: false,
                    useDeclaredValue: true,
                  })
                }
                sx={{ marginTop: 2 }}
              >
                {formatMessage(messages.addLoad)}
              </Button>
            )}
          </AccordionDetails>
        </StyledAccordion>
      </>
    ) : null
  }
  return <>{renderPackagingItem()}</>
}

export default PackagingForm
