import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import { removeKeys } from '../../utils'
import { useOrderFormContext } from '../context/OrderFormContext'
import ConsignmentForm from './ConsignmentForm'
import messages from './messages'

const ConsignmentList: React.FC = () => {
  const { control, trigger, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bookings',
  })

  const { formatMessage } = useIntl()

  const { currentPage } = useOrderFormContext()

  const copyConsignment = (index: number) => {
    const bookings = getValues('bookings')
    const booking = removeKeys(
      bookings[index],
      [
        'id',
        'shipmentBookingId',
        'loadDetailId',
        'physicalPropertiesId',
        'temperatureSettingId',
        'bookingId',
        'consignmentId',
        'waypointId',
      ],
      ['shipper', 'locationAudit']
    )
    append(booking)
  }

  return (
    <>
      {fields.map((field, index) => (
        <ConsignmentForm
          key={field.id}
          index={index}
          removeConsignment={() => remove(index)}
          copyConsignment={() => copyConsignment(index)}
        />
      ))}
      <ConditionalRender condition={currentPage === 1}>
        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={async () => {
            append({
              consignmentReferenceNumber: '',
              trackingNumber: '',
              shipFrom: {
                location: '',
                transitDetails: {
                  carrierOption: 'noPreference',
                },
              },
              deliverTo: {
                location: '',
              },
            })
          }}
          sx={{ marginTop: 2 }}
        >
          {formatMessage(messages.addConsignment)}
        </Button>
      </ConditionalRender>
    </>
  )
}

export default ConsignmentList
