import { Edit, Visibility } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_Cell, MRT_Row, MRT_TableInstance, MRT_RowSelectionState as MRTRowSelectionState } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser';
import { useGetCustomersQuery } from '../../../../app/redux-fetch/apiQuery';
import { useGetAssetHireSummaryQuery } from '../../../../app/redux-fetch/apiShipment';
import AddButton from '../../../../components/Buttons/AddButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import { default as rootEnum, default as RoutesEnum } from '../../../../components/Routes/rootEnum';
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography';
import { MRTColumnDef, OrderStatuses } from '../../../../types/common';
import messages from '../messages';
import { OrderDto } from '../types';

const AssetHireTable = () => {
    const { tenantId, customerId } = useLoggedInUser()

    const { formatMessage } = useIntl()

    const { data: assetHires = [], isLoading: assetHiresLoading } = useGetAssetHireSummaryQuery(
        {
            tenantId,
            customerId
        },
        {
            refetchOnMountOrArgChange: true,
        }
    )


    const { data: customers, isLoading: customersLoading } = useGetCustomersQuery(tenantId)

    const navigate = useNavigate()

    const currentTime = dayjs.utc();

    const handleNewAssetHire = useCallback(() => {
        navigate(rootEnum.CREATE_ASSET_HIRE)
    }, [navigate])

    const getCustomerName = (row: OrderDto): string => {
        const customer = customers?.find((c) => c.id === row.customerId)
        return customer?.name ?? 'N/A'
    }


    const createRowActions = ({
        row,
    }: {
        cell: MRT_Cell<OrderDto>
        row: MRT_Row<any>
        table: MRT_TableInstance<OrderDto>
    }) => {
        const { status } = row.original
        const isDraft = status === OrderStatuses.Draft
        return (
            <Box sx={{ display: 'flex' }}>
                <IconButton
                    color={'info'}
                    onClick={() =>
                        navigate(`${RoutesEnum.VIEW_ASSET_HIRE.replace(':id', String(row.original.id))}`)
                    }
                >
                    <Visibility />
                </IconButton>
                {isDraft && (
                    <IconButton
                        color={'info'}
                        onClick={() =>
                            navigate(`${RoutesEnum.EDIT_ASSET_HIRE.replace(':id', String(row.original.id))}`)
                        }
                    >
                        <Edit />
                    </IconButton>
                )}
            </Box>
        )
    }


    const columns = useMemo<MRTColumnDef<OrderDto>[]>(() => {
        return [
            {
                accessorKey: 'customerName',
                header: formatMessage(messages.customer),
                Cell: ({ cell }: any) => (
                    <Typography sx={{ fontWeight: 400 }}>{getCustomerName(cell.row.original)}</Typography>
                ),
            },
            {
                accessorKey: 'lastStatusChangeDate',
                header: formatMessage(messages.lastStatusChangeDate),
                accessorFn: (row) => {
                    return <Typography>{row.lastStatusChangeDate ? dayjs(row.lastStatusChangeDate).format('YYYY-MM-DD HH:ss') : ''}</Typography>
                }
            },
            {
                accessorKey: 'status',
                header: formatMessage(messages.status),
                Cell: ({ cell }: any) => {
                    return <Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>
                }
            },
            {
                accessorKey: 'bookingQuantity',
                header: formatMessage(messages.numberOfBookings),
                Cell: ({ cell }: any) => {
                    return <Typography>{cell.getValue()}</Typography>
                }
            },
        ];
    }, [formatMessage, assetHires, customers]);

    const bookingsColumns = useMemo<MRTColumnDef<any>[]>(() => {
        return [
            {
                accessorKey: 'assetConfiguration',
                header: `${formatMessage(messages.assetConfiguration)}`,
                Cell: ({ cell }: any) => {
                    return <Typography>{cell.getValue()}</Typography>
                }
            },
            {
                accessorKey: 'assetConfigurationQuantity',
                header: formatMessage(messages.assetConfigurationQuantity),
                Cell: ({ cell }: any) => {
                    return <Typography>{cell.getValue()}</Typography>
                }
            },
            {
                accessorKey: 'workforce',
                header: formatMessage(messages.workforceType),
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.workforce?.map((w: any, index: number) => (
                            <Typography key={index}>{w?.workforceType?.name}</Typography>
                        ))}
                    </div>
                ),
            },
            {
                header: formatMessage(messages.quantity),
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ row }: any) => (
                    <div>
                        {row.original.workforce?.map((w: any, index: number) => (
                            <Typography key={index}>{w.quantity}</Typography>
                        ))}
                    </div>
                ),
            },
            {
                accessorKey: 'pickupLocationName',
                header: formatMessage(messages.pickupLocationName),
                Cell: ({ cell }: any) => (
                    <Typography sx={{ fontWeight: 400 }}>{cell.getValue() ?? 'N/A'}</Typography>
                ),
            },
            {
                accessorKey: 'pickupDate',
                header: formatMessage(messages.pickupDate),
                accessorFn: (row) => {
                    return <Typography>{row.pickupDate ? dayjs(row.pickupDate).format('YYYY-MM-DD') : ''}</Typography>
                }
            },
            {
                accessorKey: 'returnLocationName',
                header: formatMessage(messages.returnLocationName),
                Cell: ({ cell }: any) => (
                    <Typography sx={{ fontWeight: 400 }}>{cell.getValue() ?? 'N/A'}</Typography>
                ),
            },
            {
                accessorKey: 'returnDate',
                header: formatMessage(messages.returnDate),
                accessorFn: (row) => {
                    return <Typography>{row.pickupDate ? dayjs(row.pickupDate).format('YYYY-MM-DD') : ''}</Typography>
                }
            },
        ];
    }, [formatMessage, assetHires, customers]);

    const isRowSelectable = useCallback(
        (row: MRT_Row<OrderDto>) => {
            const { underReviewUntil, acceptedDate, submittedDate } = row.original;
            const isUnderReview =
                underReviewUntil && dayjs.utc(underReviewUntil).isAfter(currentTime);

            const statusMessage =
                (isUnderReview && formatMessage(messages.lockForReview)) ||
                (acceptedDate && formatMessage(messages.acceptedStatus)) ||
                (submittedDate && formatMessage(messages.submittedStatus)) ||
                formatMessage(messages.draftStatus);

            return statusMessage !== formatMessage(messages.acceptedStatus);
        },
        [formatMessage, currentTime]
    );


    const renderConsignmentTable = ({ row }: any) => {
        return (
            <MRTDataGridv2
                state={{ isLoading: false }}
                columns={bookingsColumns}
                data={row.original.assetHireBookingSummaries ?? []}
                getRowId={(row) => row.id as any}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
            />
        )
    }

    const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({});
    const [selectedAssetHires, setSelectedAssetHires] = useState<OrderDto[]>([]);

    useEffect(() => {
        const selectedRowIds = Object.keys(rowSelection);
        const selectedRows = assetHires.filter((assetHire: any) =>
            selectedRowIds.includes(String(assetHire.id))
        );
        setSelectedAssetHires(selectedRows);
    }, [rowSelection, assetHires]);

    return (<>
        <MRTDataGridv2
            initialState={{
                pagination: { pageSize: 100, pageIndex: 0 },
                density: 'comfortable',
                sorting: [
                    {
                        id: 'status',
                        desc: false,
                    },
                ],
            }}
            state={{
                isLoading: assetHiresLoading || customersLoading,
                rowSelection: rowSelection,
            }}
            leftHeadingComponent={
                <Box sx={{ display: 'inline-flex' }}>
                    <TableTitleTypography>{formatMessage(messages.assetHires)}</TableTitleTypography>
                </Box>
            }
            rightHeadingComponent={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <AddButton
                        text={formatMessage(messages.newAssetHire)}
                        onClick={handleNewAssetHire}
                    />
                </Box>
            }
            columns={columns}
            data={assetHires}
            getRowId={(row) => row.id as any}
            enableEditing
            enableRowActions
            enableExpanding
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection}
            positionActionsColumn='last'
            renderRowActions={createRowActions}
            renderDetailPanel={renderConsignmentTable}
        />
    </>)
}

export default AssetHireTable;
