import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import {
  useGetCurrenciesByCustomerIdQuery,
  useGetShipmentByIdQuery,
} from '../../../../app/redux-fetch/apiShipment'
import { TableColumn, TableItem, TableItemTypography } from '../../../../components/Headers/styles'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'

const Timeline = () => {
  const { id } = useParams()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  const isTenantUser = tenantId && !customerId

  const renderMoneyValue = (value: number, currencyId: number) => {
    return `${currencies.find((c) => c.id === currencyId)?.iso3} ${value
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  const { data: order } = useGetShipmentByIdQuery({
    shipmentId: Number(id),
    tenantId,
  })

  const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery(
    {
      customerId: order?.customerId ?? 0,
      hideDisabled: true,
    },
    { skip: !order }
  )

  const orderStatuses = useMemo(() => order?.statuses || [], [order])
  const bookings = useMemo(() => order?.bookings || [], [order])

  return (
    <Box display='flex' flexDirection='column' sx={{ pt: '32px' }}>
      {/* Order Status Timeline */}
      {orderStatuses.length > 0 && (
        <>
          <Box display='flex'>
            <TableColumn>
              <TableItemTypography variant='body1'>Date</TableItemTypography>
            </TableColumn>
            <TableColumn>
              <TableItemTypography variant='body1'>Status</TableItemTypography>
            </TableColumn>
            <TableColumn>
              <TableItemTypography variant='body1'>Updated By</TableItemTypography>
            </TableColumn>
          </Box>
          {orderStatuses.map((status: any, idx: any) => (
            <Box key={`status-${idx}`} display='flex'>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{dayjs(status.statusDate).format('DD/MM/YYYY HH:mm')}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{status.orderStatusType.name}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{`${status.user?.firstName} ${status.user?.lastName}`}</Typography>
              </TableItem>
            </Box>
          ))}
          <Box sx={{ my: 3, borderBottom: '1px solid #e0e0e0' }} />
        </>
      )}

      <Box display='flex'>
        <TableColumn>
          <TableItemTypography variant='body1'>Load</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1'>Quantity</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1'>Description</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1'>Pickup Location</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1'>Consignee</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1'>Requested Pickup Date</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1'>Requested Delivery Date</TableItemTypography>
        </TableColumn>
        <TableColumn>
          <TableItemTypography variant='body1' sx={{ textAlign: 'center' }}>
            Value
          </TableItemTypography>
        </TableColumn>
      </Box>
      {bookings.map((load: any, idx: any) => {
        const routeLoadHistories = isTenantUser
          ? load.routeLoadHistories
          : load.routeLoadHistories?.filter((history: any) => history.completedDate)
        return (
          <>
            <Box key={idx} display='flex'>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography sx={{ fontWeight: 700 }}>Load #{load?.displayOrder}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{load.quantity}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{load.description}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{load.transportDetail?.pickupLocation?.name}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>{load.transportDetail?.deliveryLocation?.name}</Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>
                  {dayjs(load.transportDetail?.pickupDate).format('DD/MM/YYYY')}
                </Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0}>
                <Typography>
                  {dayjs(load.transportDetail?.deliveryDate).format('DD/MM/YYYY')}
                </Typography>
              </TableItem>
              <TableItem isOdd={idx % 2 === 0} sx={{ textAlign: 'right' }}>
                <Typography>{renderMoneyValue(load.value ?? 0, load.currencyId ?? -1)}</Typography>
              </TableItem>
            </Box>
            {routeLoadHistories && routeLoadHistories.length > 0 && (
              <Box display='flex' sx={{ pl: '36px' }}>
                {isTenantUser && (
                  <TableColumn>
                    <TableItemTypography variant='body1'>Scheduled Date</TableItemTypography>
                  </TableColumn>
                )}
                <TableColumn>
                  <TableItemTypography variant='body1'>Completed Date</TableItemTypography>
                </TableColumn>
                <TableColumn>
                  <TableItemTypography variant='body1'>Location</TableItemTypography>
                </TableColumn>
                <TableColumn>
                  <TableItemTypography variant='body1'>Status</TableItemTypography>
                </TableColumn>
              </Box>
            )}

            {routeLoadHistories &&
              routeLoadHistories.map((history: any, aIdx: any) => {
                const isColoredBackground = aIdx % 2 === 0
                return (
                  <Box key={aIdx} display='flex' sx={{ pl: '36px' }}>
                    {isTenantUser && (
                      <TableItem isOdd={isColoredBackground}>
                        <Typography sx={{ fontWeight: 700 }}>
                          {dayjs(history.scheduledDate).format('DD/MM/YYYY HH:mm')}
                        </Typography>
                      </TableItem>
                    )}
                    <TableItem isOdd={isColoredBackground}>
                      <Typography sx={{ ...(!isTenantUser && { fontWeight: 700 }) }}>
                        {history.completedDate
                          ? dayjs(history.completedDate).format('DD/MM/YYYY HH:mm')
                          : 'Scheduled'}
                      </Typography>
                    </TableItem>
                    <TableItem isOdd={isColoredBackground}>
                      <Typography>{history.location}</Typography>
                    </TableItem>
                    <TableItem isOdd={isColoredBackground}>
                      <Typography>{history.action}</Typography>
                    </TableItem>
                  </Box>
                )
              })}
          </>
        )
      })}
    </Box>
  )
}

export default Timeline
