import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../app/hooks/useNotificationStack'
import { useTrackingNumberTemplateQuery } from '../../../app/redux-fetch/apiQuery'
import {
  useAcceptOrderByTenantIdMutation,
  useCreateShipmentMutation,
  useSubmitOrderMutation,
  useUpdateShipmentMutation,
} from '../../../app/redux-fetch/apiShipment'
import CustomAlert from '../../../components/Alerts/CustomAlert'
import TooltipButton from '../../../components/Buttons/TooltipButton'
import commonMessages from '../../../components/Messages/commonMessages'
import { OrderStatuses } from '../../../types/common'
import { normalizeDate } from '../../utils'
import { OrderFormProvider } from '../context/OrderFormContext'
import ConsignmentList from './ConsignmentList'
import messages from './messages'
import ShipmentInformation from './ShipmentInformation'
import ShipmentSummary from './ShipmentSummary'
import { mapToShipmentData } from './utils'

const transitDetailSchema = z.object({
  id: z.number().optional(),
  carrierId: z.preprocess((val) => {
    if (val === '' || val === null || val === undefined) return undefined
    const parsed =
      typeof val === 'string' ? parseInt(val, 10) : typeof val === 'number' ? val : Number(val)
    return isNaN(parsed) ? undefined : parsed
  }, z.number().optional()),
  crossBorder: z.boolean().optional(),
  modeOfTransit: z
    .number({ message: 'Mode of transit is required' })
    .min(-1, { message: 'Mode of transit is required' }),
  transitIdentifier: z.string().optional(),
  consignmentStopId: z.number().optional(),
  carrierOption: z.string().optional(),
})

const schema = z.object({
  id: z.number().optional(),
  tenantId: z.number().min(1, { message: 'Tenant is required' }),
  customerId: z.number().min(1, { message: 'Customer is required' }),
  revision: z.number().optional(),
  customerReferenceNumber: z.string().min(1, { message: 'Customer Reference Number is required' }),
  shipmentNumber: z.string().optional(),
  submittedDate: z.date().optional(),
  statuses: z.array(z.any()).optional(),
  status: z.string().optional(),
  description: z.string().min(1, { message: 'Description is required' }),
  bookings: z.array(
    z
      .object({
        id: z.number().optional(),
        bookingId: z.number().optional(),
        referenceNumber: z.string(),
        trackingNumber: z.string().optional(),
        shipperId: z
          .number({ message: 'Shipper is required' })
          .min(1, { message: 'Shipper is required' }),
        shipper: z.object({}).optional(),
        currencyId: z
          .number({ message: 'Currency is required' })
          .min(1, { message: 'Currency is required' }),
        description: z.string().min(1, { message: 'Description is required' }),
        emptyContainerCollection: z
          .object({
            id: z.number().optional(),
            locationId: z
              .number({ message: 'Empty Container Collection location is required' })
              .min(1, { message: 'Empty Container Collection location is required' }),
            contactId: z.number().optional().nullable(),
            scheduledDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
            locationAudit: z.object({}).optional(),
            specialInstructions: z.string().optional(),
            transitDetails: transitDetailSchema,
          })
          .optional(),
        emptyContainerReturn: z
          .object({
            id: z.number().optional(),
            locationId: z
              .number({ message: 'Empty Container Return location is required' })
              .min(1, { message: 'Empty Container Return location is required' }),
            contactId: z.number().optional().nullable(),
            scheduledDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
            locationAudit: z.object({}).optional(),
            specialInstructions: z.string().optional(),
          })
          .optional(),
        returnTo: z
          .object({
            id: z.number().optional(),
            locationId: z
              .number({ message: 'Location is required' })
              .min(1, { message: 'Empty Container Return location is required' }),
            contactId: z.number().optional().nullable(),
            defaultValue: z.boolean().optional(),
            locationAudit: z.object({}).optional(),
            specialInstructions: z.string().optional(),
          })
          .optional(),
        shipFrom: z.object({
          id: z.number().optional(),
          locationId: z
            .number({ message: 'Ship From location is required' })
            .min(1, { message: 'Ship From location is required' }),
          consignmentId: z.number().optional(),
          locationAudit: z.object({}).optional(),
          returnTo: z.boolean().optional(),
          emptyContainerCollection: z.boolean().optional(),
          contactId: z.number().nullable().optional(),
          clearingRequired: z.boolean().optional(),
          defaultValue: z.boolean().optional(),
          specialInstructions: z.string().optional(),
          loadingDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
          transitDetails: transitDetailSchema,
        }),
        deliverTo: z.object({
          id: z.number().optional(),
          consignmentId: z.number().optional(),
          locationId: z
            .number({ message: 'Deliver To location is required' })
            .min(1, { message: 'Deliver To location is required' }),
          clearingRequired: z.boolean().optional().nullable(),
          locationAudit: z.object({}).optional(),
          contactId: z.number().nullable().optional(),
          deliveryDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
          specialInstructions: z.string().optional(),
          emptyContainerReturn: z.boolean().optional(),
          transitDetails: transitDetailSchema.optional(),
        }),
        packaging: z
          .array(
            z.object({
              id: z.number().optional(),
              packagingTemplateId: z.number().optional(),
              isHazardous: z.boolean().optional(),
              physicalPropertiesId: z.number().optional(),
              shipmentBookingId: z.number().optional(),
              packagingTypeId: z.number().min(1, { message: 'Load type is required' }),
              сonsignmentId: z.number().optional(),
              packagingDetailId: z.number().optional(),
              numberOfPackages: z.number({ message: 'Number of packages is required' }).optional(),
              useDeclaredValue: z.boolean().optional(),
              declaredContentDescription: z.string().nullable().optional(),
              weight: z.number().optional(),
              length: z.number().optional(),
              width: z.number().optional(),
              height: z.number().optional(),
              amountInsured: z.number().optional(),
              insuranceRequired: z.boolean().optional(),
              temperatureControlled: z.boolean().optional(),
              temperatureSettingId: z.number().optional(),
              temperatureRange: z.number().optional(),
              setPointUnitId: z.number().optional(),
              setPoint: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              lowerWarning: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              upperWarning: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              lowerCritical: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              upperCritical: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              isStackable: z.boolean().optional(),
              weightUnit: z.number().optional(),
              dimensionUnit: z.number().optional(),
              declaredValue: z.preprocess(
                (val) => (val === '' ? null : val),
                z.number().nullable().optional()
              ),
              products: z
                .array(
                  z.object({
                    id: z.number().optional(),
                    productDescription: z
                      .string({ message: 'Product description is required' })
                      .min(1, { message: 'Product description is required' }),
                    productId: z.number().optional().nullable(),
                    countryOfOriginId: z.number().nullable().optional(),
                    quantity: z.number({ message: 'Quantity is required' }),
                    unitPrice: z.number().optional(),
                    useProduct: z.boolean().optional(),
                    defaultValue: z.boolean().optional(),
                    productName: z.string().optional(),
                  })
                )
                .optional(),
            })
          )
          .optional(),
        waypoints: z
          .array(
            z.object({
              id: z.number().optional(),
              locationId: z
                .number({ message: 'Waypoint location is required' })
                .min(1, { message: 'Waypoint location is required' }),
              clearingRequired: z.boolean().optional(),
              loadingDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
              specialInstructions: z.string().optional(),
              consignmentId: z.number().optional(),
              locationAudit: z.object({}).optional(),
              contactId: z.number().nullable().optional(),
              transitDetails: transitDetailSchema,
            })
          )
          .optional(),
      })
      .superRefine((data, context) => {
        // ----------------------------
        // 1. The existing date checks
        // ----------------------------
        const { emptyContainerCollection, shipFrom, waypoints, deliverTo, emptyContainerReturn } =
          data
        const emptyContainerCollectionDate = emptyContainerCollection?.scheduledDate
        const loadingDate = shipFrom?.loadingDate
        const deliveryDate = deliverTo?.deliveryDate
        const emptyContainerReturnDate = emptyContainerReturn?.scheduledDate

        let previousDate: any = null

        if (emptyContainerCollectionDate) {
          const eccDate = normalizeDate(emptyContainerCollectionDate)
          previousDate = eccDate
        }

        if (!loadingDate) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Ship From Scheduled Date is required',
            path: ['shipFrom', 'loadingDate'],
          })
          return
        } else {
          const loadDate = normalizeDate(loadingDate)
          if (previousDate && loadDate <= previousDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
              path: ['shipFrom', 'loadingDate'],
            })
          }
          previousDate = loadDate
        }

        if (waypoints && waypoints.length > 0) {
          waypoints.forEach((waypoint, index) => {
            const waypointDate = waypoint.loadingDate
            if (!waypointDate) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Waypoint ${index + 1}: Scheduled date is required`,
                path: ['waypoints', index, 'loadingDate'],
              })
            } else {
              const wDate = normalizeDate(waypointDate)
              if (previousDate && wDate <= previousDate) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
                  path: ['waypoints', index, 'loadingDate'],
                })
              }
              previousDate = wDate
            }
          })
        }

        if (deliveryDate) {
          const delDate = normalizeDate(deliveryDate)
          if (previousDate && delDate <= previousDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
              path: ['deliverTo', 'deliveryDate'],
            })
          }
          previousDate = delDate
        }

        if (emptyContainerReturnDate) {
          const ecrDate = normalizeDate(emptyContainerReturnDate)
          if (previousDate && ecrDate <= previousDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
              path: ['emptyContainerReturn', 'scheduledDate'],
            })
          }
        }

        // ----------------------------------------
        // 2. Check if any crossBorder is "true"
        // ----------------------------------------
        const isCrossBorder =
          emptyContainerCollection?.transitDetails?.crossBorder === true ||
          shipFrom?.transitDetails?.crossBorder === true ||
          deliverTo?.transitDetails?.crossBorder === true ||
          (waypoints || []).some((wp) => wp?.transitDetails?.crossBorder === true)

        // ------------------------------------------------
        // 3. If crossBorder is true, countryOfOriginId is
        //    required for every product in packaging
        // ------------------------------------------------
        if (isCrossBorder) {
          if (data.packaging && Array.isArray(data.packaging)) {
            data.packaging.forEach((pack, pIndex) => {
              if (pack.products && Array.isArray(pack.products)) {
                pack.products.forEach((product, prodIndex) => {
                  if (!product.countryOfOriginId) {
                    context.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: 'Country of Origin is required',
                      path: ['packaging', pIndex, 'products', prodIndex, 'countryOfOriginId'],
                    })
                  }
                })
              }
            })
          }
        }

        // ------------------------------------------------
        // 4. Temperature control validation
        // ------------------------------------------------
        if (data.packaging && Array.isArray(data.packaging)) {
          data.packaging.forEach((pack, pIndex) => {
            if (pack.temperatureControlled) {
              if (pack.temperatureRange === undefined || pack.temperatureRange === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Temperature Range is required',
                  path: ['packaging', pIndex, 'temperatureRange'],
                })
              }
              if (pack.setPoint === undefined || pack.setPoint === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Set Point is required',
                  path: ['packaging', pIndex, 'setPoint'],
                })
              }
              if (!pack.setPointUnitId) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Set Point Unit is required',
                  path: ['packaging', pIndex, 'setPoint'],
                })
              }
              if (pack.lowerWarning === undefined || pack.lowerWarning === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Lower Warning is required',
                  path: ['packaging', pIndex, 'lowerWarning'],
                })
              }
              if (pack.upperWarning === undefined || pack.upperWarning === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Upper Warning is required',
                  path: ['packaging', pIndex, 'upperWarning'],
                })
              }
              if (pack.lowerCritical === undefined || pack.lowerCritical === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Lower Critical is required',
                  path: ['packaging', pIndex, 'lowerCritical'],
                })
              }
              if (pack.upperCritical === undefined || pack.upperCritical === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Upper Critical is required',
                  path: ['packaging', pIndex, 'upperCritical'],
                })
              }
            }
          })
        }

        // ------------------------------------------------
        // 5. Carrier validation
        // ------------------------------------------------
        const validateCarrierForTransitDetails = (
          transitDetails: any,
          path: (string | number)[]
        ) => {
          if (
            (transitDetails?.carrierOption === 'preferredCarrier' ||
              transitDetails?.carrierOption === 'preBookedTransit') &&
            !transitDetails?.carrierId
          ) {
            switch (transitDetails?.carrierOption) {
              case 'preferredCarrier':
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Carrier is required when Preferred Carrier is selected',
                  path: [...path, 'transitDetails', 'carrierId'],
                })
                break
              case 'preBookedTransit':
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Carrier is required when Pre-Booked Transit is selected',
                  path: [...path, 'transitDetails', 'carrierId'],
                })
                break
            }
          }
        }

        // Check Empty Container Collection
        if (emptyContainerCollection?.transitDetails) {
          validateCarrierForTransitDetails(emptyContainerCollection.transitDetails, [
            'emptyContainerCollection',
          ])
        }

        // Check Ship From
        if (shipFrom?.transitDetails) {
          validateCarrierForTransitDetails(shipFrom.transitDetails, ['shipFrom'])
        }

        // Check Waypoints
        if (waypoints) {
          waypoints.forEach((waypoint, index) => {
            if (waypoint.transitDetails) {
              validateCarrierForTransitDetails(waypoint.transitDetails, ['waypoints', index])
            }
          })
        }

        // Check Deliver To
        if (deliverTo?.transitDetails) {
          validateCarrierForTransitDetails(deliverTo.transitDetails, ['deliverTo'])
        }
      })
  ),
})

export type IShipmentFormInput = z.infer<typeof schema>

interface ShipmentFormProps {
  initialValues?: Partial<IShipmentFormInput>
  onSubmit: (data: IShipmentFormInput) => void
  isEdit?: boolean
  onCancel?: () => void
}

const ShipmentForm: React.FC<ShipmentFormProps> = ({ initialValues, isEdit = false, onSubmit }) => {
  const { tenantId, customerId, isTenantUser } = useLoggedInUser()

  const { formatMessage } = useIntl()

  const methods = useForm<IShipmentFormInput>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: initialValues || {
      tenantId: tenantId,
      customerId: customerId,
      bookings: [
        {
          shipFrom: {},
          deliverTo: {},
        },
      ],
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid, errors },
    watch,
    trigger,
  } = methods

  React.useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  console.log('errors', errors)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [currentPage, setCurrentPage] = useState(1)

  const [createShipment, { isLoading }] = useCreateShipmentMutation()
  const [updateShipment, { isLoading: isUpdating }] = useUpdateShipmentMutation()

  const [submitOrder] = useSubmitOrderMutation()
  const [acceptOrder] = useAcceptOrderByTenantIdMutation()

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const [isLoadRequired, setIsLoadRequired] = useState(false)
  const [isLoadContentRequired, setIsLoadContentRequired] = useState(false)

  const {
    data: template,
    isFetching: isTemplateFetching,
    isSuccess: isTemplateLoaded,
    refetch,
  } = useTrackingNumberTemplateQuery({ tenantId })

  const isTrackingNumberSetup = useCallback(() => !!template, [template])

  const navigate = useNavigate()

  const isSubmitted = false
  const customer = watch('customerId')
  const bookings = watch('bookings')
  const status = watch('status')

  const validateStops = async () => {
    const fieldsToTrigger = []

    for (let i = 0; i < bookings?.length; i++) {
      fieldsToTrigger.push(`bookings.${i}.emptyContainerCollection`)
      fieldsToTrigger.push(`bookings.${i}.emptyContainerReturn`)
      fieldsToTrigger.push(`bookings.${i}.waypoints`)
      fieldsToTrigger.push(`bookings.${i}.shipFrom`)
      fieldsToTrigger.push(`bookings.${i}.deliverTo`)
      fieldsToTrigger.push(`bookings.${i}.returnTo`)
    }

    return await trigger(fieldsToTrigger as any)
  }

  const validateLoads = async () => {
    const fieldsToTrigger = []

    for (let i = 0; i < bookings?.length; i++) {
      const booking = bookings[i]
      if (!booking || !booking.packaging) continue

      for (let j = 0; j < booking?.packaging?.length; j++) {
        const packaging = booking.packaging[j]
        if (!packaging) continue

        const packagingFields = Object.keys(packaging)
          .filter((key) => key !== 'products')
          .map((key) => `bookings.${i}.packaging.${j}.${key}`)

        fieldsToTrigger.push(...packagingFields)
      }
    }

    return await trigger(fieldsToTrigger as any)
  }

  const validateLoadsWithProducts = async () => {
    const fieldsToTrigger = []

    for (let i = 0; i < bookings?.length; i++) {
      const booking = bookings[i]
      if (!booking || !booking.packaging) continue

      for (let j = 0; j < booking?.packaging?.length; j++) {
        const packaging = booking.packaging[j]
        if (!packaging) continue

        const packagingFields = Object.keys(packaging).map(
          (key) => `bookings.${i}.packaging.${j}.${key}`
        )

        fieldsToTrigger.push(...packagingFields)

        if (packaging.products && Array.isArray(packaging.products)) {
          for (let k = 0; k < packaging.products.length; k++) {
            const productFields = Object.keys(packaging.products[k]).map(
              (key) => `bookings.${i}.packaging.${j}.products.${k}.${key}`
            )
            fieldsToTrigger.push(...productFields)
          }
        }
      }
    }

    return await trigger(fieldsToTrigger as any)
  }

  const handleSubmitForm = async (
    data: IShipmentFormInput,
    onAfterRequest?: (responseData?: any) => void
  ) => {
    const payload = mapToShipmentData(data)
    let isErrorOccurred = false
    if (payload) {
      let response
      try {
        if (isEdit) {
          response = await updateShipment({ shipment: payload, tenantId, customerId }).unwrap()
          enqueueSuccess(messages.shipmentUpdated)
        } else {
          response = await createShipment({ shipment: payload, tenantId, customerId }).unwrap()
          enqueueSuccess(messages.shipmentCreated)
        }
      } catch (error) {
        console.error('Failed to submit shipment:', error)
        enqueueFailure(messages.shipmentSaveDraftFailed)
        isErrorOccurred = true
      } finally {
        if (onAfterRequest && !isErrorOccurred) {
          onAfterRequest(response)
        }
      }
    }
  }

  const handleSubmitShipment = async () => {
    const data = methods.getValues()
    handleSubmitForm(data, async (response) => {
      if (response) {
        try {
          await submitOrder({ tenantId, id: response.id }).unwrap()
          enqueueSuccess(messages.shipmentSubmitted)
          navigate('/shipping/shipments')
        } catch (error) {
          enqueueFailure(messages.shipmentSubmitFailed)
        }
      }
    })
  }

  const handleAccept = async () => {
    const data = methods.getValues()
    handleSubmitForm(data, async (response) => {
      if (response) {
        try {
          if (!isSubmitted) {
            await submitOrder({ tenantId, id: response.id }).unwrap()
          }
          await acceptOrder({ tenantId, id: response.id }).unwrap()
          enqueueSuccess(messages.shipmentAccepted)
          navigate('/shipping/shipments')
        } catch (error) {
          enqueueFailure(messages.shipmentAcceptFailed)
        }
      }
    })
  }

  const handleNext = async () => {
    let isValid = false
    if (currentPage === 1) {
      isValid = await validateStops()
    } else if (currentPage === 2) {
      const loadRequired =
        !isConsignmentRequired &&
        bookings?.length > 0 &&
        !bookings.some((booking) => booking.packaging && booking.packaging.length > 0)

      setIsLoadRequired(loadRequired)

      isValid = (await validateLoads()) && !loadRequired
    } else if (currentPage === 3) {

      const loadContentRequired =
        bookings?.length > 0 &&
        !bookings.some((booking) =>
          booking.packaging?.every((pack) =>
            pack.useDeclaredValue
              ? pack.declaredValue !== undefined && pack.declaredValue !== null
              : pack.products && pack.products?.length > 0
          )
        )

      setIsLoadContentRequired(loadContentRequired)

      isValid = (await validateLoadsWithProducts()) && !loadContentRequired
    } else {
      isValid = true
    }

    if (isValid) {
      setCurrentPage((prev) => prev + 1)
    }
  }

  const handleSaveDraft = async () => {
    const data = methods.getValues()
    const isValid = await methods.trigger()
    handleSubmitForm(data, () => {
      navigate('/shipping/shipments')
    })
  }

  const handleBack = async () => {
    let isValid = false
    if (currentPage === 1) {
      return
    } else if (currentPage === 2) {
      isValid = await validateLoads()
    } else if (currentPage === 3) {
      isValid = await validateLoadsWithProducts()
    } else {
      isValid = true
    }

    if (isValid) {
      setCurrentPage((prev) => prev - 1)
    }
  }

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name?.includes('shipFrom.loadingDate') || name?.includes('deliverTo.deliveryDate')) {
        methods.trigger()
      }
    })
    return () => subscription.unsubscribe()
  }, [methods])

  const steps = [
    { label: formatMessage(messages.consignment) },
    { label: formatMessage(messages.load) },
    { label: formatMessage(messages.contents) },
    { label: formatMessage(messages.summary) },
  ]

  const activeStep = currentPage <= 3 ? currentPage - 1 : 3

  const isConsignmentRequired =
    (!bookings || bookings?.length === 0) && (currentPage !== 1 || isEdit)

  const getTooltipMessage = () => {
    if (isConsignmentRequired) {
      return formatMessage(messages.noConsignment)
    }
    if (isLoadContentRequired && !isLoadRequired) {
      return formatMessage(messages.noLoadContent)
    }
    return formatMessage(messages.noLoad)
  }

  const isRejected = status === OrderStatuses.Rejected

  return (
    <Paper elevation={3} sx={{ padding: 4 }}>
      <Box
        sx={{
          backgroundColor: '#f0f0f0',
          padding: 2,
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((s, index) => (
            <Step key={s.label}>
              <StepLabel>{s.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <OrderFormProvider currentPage={currentPage} totalPages={3} setCurrentPage={setCurrentPage}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display='flex'
              flexDirection={isSmallScreen ? 'column' : 'row'}
              justifyContent='flex-end'
              alignItems={isSmallScreen ? 'flex-start' : 'center'}
              sx={{ marginBottom: 2 }}
            >
              <Box>
                {currentPage > 1 && (
                  <Button
                    variant='contained'
                    sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
                    onClick={handleBack}
                  >
                    {formatMessage(commonMessages.previous)}
                  </Button>
                )}

                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSaveDraft}
                  disabled={!isTrackingNumberSetup()}
                  sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} />
                  ) : (
                    formatMessage(messages.saveDraft)
                  )}
                </Button>
                {currentPage === 4 && isTenantUser && !isRejected ? (
                  <TooltipButton
                    variant='contained'
                    color='primary'
                    onClick={handleAccept}
                    disabled={isConsignmentRequired || isLoadRequired || !isTrackingNumberSetup()}
                    showTooltip={isConsignmentRequired || isLoadRequired}
                    tooltipProps={{
                      placement: 'top',
                      title: getTooltipMessage(),
                      children: <></>,
                    }}
                  >
                    {isSubmitted
                      ? formatMessage(messages.acceptShipment)
                      : formatMessage(messages.submitAndAccept)}
                  </TooltipButton>
                ) : (
                  currentPage < 4 && (
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={!isTrackingNumberSetup()}
                      onClick={handleNext}
                    >
                      {formatMessage(commonMessages.next)}
                    </Button>
                  )
                )}
                {currentPage === 4 && !isRejected && (
                  <>
                    <TooltipButton
                      variant='contained'
                      color='primary'
                      sx={{ marginLeft: 1 }}
                      disabled={isConsignmentRequired || isLoadRequired || !isTrackingNumberSetup()}
                      showTooltip={isConsignmentRequired || isLoadRequired}
                      tooltipProps={{
                        placement: 'top',
                        title: getTooltipMessage(),
                        children: <></>,
                      }}
                      onClick={handleSubmitShipment}
                    >
                      {formatMessage(messages.submitShipment)}
                    </TooltipButton>
                  </>
                )}
              </Box>
            </Box>
            {isTrackingNumberSetup() && isConsignmentRequired && (
              <CustomAlert
                severity='error'
                title='Error'
                message={formatMessage(messages.noConsignment)}
              />
            )}
            {isTrackingNumberSetup() && isLoadRequired && (
              <CustomAlert
                severity='error'
                title='Error'
                message={formatMessage(messages.noLoad)}
              />
            )}
            {isTrackingNumberSetup() && isLoadContentRequired && !isLoadRequired && (
              <CustomAlert
                severity='error'
                title='Error'
                message={formatMessage(messages.noLoadContent)}
              />
            )}
            {isTrackingNumberSetup() && (
              <>
                {currentPage !== 4 ? (
                  <>
                    <ShipmentInformation isEdit={isEdit} />
                    {currentPage > 0 && customer && <ConsignmentList />}
                  </>
                ) : (
                  currentPage === 4 && <ShipmentSummary data={methods.getValues()} />
                )}
              </>
            )}
          </form>
        </FormProvider>
      </OrderFormProvider>
    </Paper>
  )
}

export default ShipmentForm
