import { useCallback, useState } from 'react'
import SideModalWrapper from '../../features/AssetsManagement/modals/SideModalWrapper'
import LocationForm from '../../features/Locations/LocationForm'
import ProductForm from '../../features/Products/ProductTab/ProductForm'
import { IProduct } from '../../features/Products/types'

interface UseModalProps {
  refetch?: () => void
  onSave?: (data: any) => void
  type: 'location' | 'productModal'
  data?: any
}

export const useModalWithForm = ({ refetch, onSave, type, data }: UseModalProps) => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    if (refetch) refetch()
    setIsModalOpen(false)
  }, [refetch])

  const saveModal = useCallback(
    (data: any) => {
      if (onSave) onSave(data)
      setModalData(data)
      setIsModalOpen(false)
    },
    [onSave]
  )

  const renderForm = () => {
    switch (type) {
      case 'location':
        return (
          <LocationForm
            isCustomerDisabled={false}
            location={{
              ...data,
            }}
            onSubmit={(data) => {
              saveModal(data)
            }}
          />
        )
      case 'productModal':
        return (
          <ProductForm
            product={data as IProduct}
            isDisabled={false}
            onSubmit={(data) => {
              saveModal(data)
            }}
          />
        )
      default:
        return null
    }
  }

  const ModalComponent = (
    <SideModalWrapper
      open={isModalOpen}
      headerLabel={type === 'location' ? 'Location Form' : 'Product Form'}
      onClose={closeModal}
      onSave={() => saveModal(modalData)}
    >
      {renderForm()}
    </SideModalWrapper>
  )

  return {
    isModalOpen,
    openModal,
    closeModal,
    saveModal,
    modalData,
    ModalComponent,
  }
}
