import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'
import {
  useAddWorkforceTypeMutation,
  useUpdateWorkforceTypeMutation,
} from '../../app/redux-fetch/apiUser'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../components/Typographies/styles'
import { messages } from './messages'
import { WorkforceTypeFormData, workforceTypeSchema } from './schema'
import { IWorkforceType } from './types'

type WorkforceTypeFormProps = {
  workforceType: IWorkforceType
  handleClose: () => void
  isOpen: boolean
}

const WorkforceTypeForm = ({ workforceType, handleClose, isOpen }: WorkforceTypeFormProps) => {
  const { formatMessage } = useIntl()
  const isNew = !workforceType?.id || workforceType?.id === 0

  const [addWorkforceType] = useAddWorkforceTypeMutation()
  const [updateWorkforceType] = useUpdateWorkforceTypeMutation()
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const {
    register,
    handleSubmit,
    formState: { errors },

  } = useForm<WorkforceTypeFormData>({
    resolver: zodResolver(workforceTypeSchema),
    defaultValues: workforceType,
  })

  const onSubmit = async (data: WorkforceTypeFormData) => {
    if (isNew) {
      await addWorkforceType({ tenantId: data.tenantId, body: data })
        .then(() => {
          enqueueSuccess(messages.workforceTypeCreated)
          handleClose()
        })
        .catch(() => enqueueFailure(messages.workforceTypeNotAdded))
    } else {
      await updateWorkforceType({ tenantId: data.tenantId, body: data })
        .then(() => {
          enqueueSuccess(messages.workforceTypeUpdated)
          handleClose()
        })
        .catch(() => enqueueFailure(messages.workforceTypeNotUpdated))
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <SectionLabelTypography>
          {formatMessage(messages.createEditWorkforceType)}
        </SectionLabelTypography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ minWidth: '450px' }}>
          <Box sx={{ mb: '16px' }}>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.name)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <TextField
                fullWidth
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.description)}
              </SectionLabelTypography>
              <TextField
                fullWidth
                multiline
                rows={4}
                {...register('description')}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' variant='outlined' onClick={handleClose}>
            {formatMessage(messages.cancelBtn)}
          </Button>
          <Button
            color='secondary'
            variant='contained'
            type='submit'
          >
            {isNew ? formatMessage(messages.newWorkforceTypeBtn) : formatMessage(messages.editBtn)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default WorkforceTypeForm
