import { z } from 'zod'

export const workforceTypeSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  tenantId: z.number(),
  id: z.number().optional(),
  inUse: z.boolean().optional().default(true),
})

export type WorkforceTypeFormData = z.infer<typeof workforceTypeSchema>
